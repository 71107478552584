import {
    Outlet
} from '@tanstack/react-location'
import { useAuth } from '../../customHooks/auth'

export default function AuthorizedPages() {

    useAuth()

    return (
        <Outlet />
    )

}
