import { atom } from 'jotai'
import { LOCAL_STORAGE_TOKEN_KEY } from '../../customHooks/auth'
import { User, Ticket, AcceptDialog } from '../../types/index'

const defaultUser = {
  id: 0,
  bje_username: '',
  bje_email: '',
  salutation: '',
  firstname: '',
  lastname: '',
  company: {
    name: '',
    address: '',
    postalcode: '',
    city: '',
    authority: [],
    target_group: ''
  },
  email: '',
  phone: '',
  consentApproved: false,
  authority: '',
  target_group: '',
  kinds: []
}

const token = window.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)

export const userAtom = atom<User>(defaultUser)
export const acceptDialogAtom = atom<AcceptDialog | undefined>(undefined)
export const consentApprovalDialogAtom = atom<boolean | undefined>(false)
export const inviteDialogAtom = atom<Ticket[] | undefined>(undefined)
export const ExportDialogAtom = atom<boolean>(false)
export const crmClientAtom = atom<unknown>(null)
export const accessTokenAtom = atom<string | null>(token)
export const lastInsertedAtom = atom<Ticket[] | null>(null)
export const imageOverlayOpened = atom<boolean>(false)
