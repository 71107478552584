import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import { format } from 'date-fns'
import { de } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { useQuery } from 'react-query'

import ErrorBoundary, { ErrorFallback } from '../ErrorBoundary'
import { getAppointmentsCountForMonth } from '../../utils/api'
import { AppointmentForMonth } from '../../types'

import previousIcon from '../../../assets/img/previous.svg'
import nextIcon from '../../../assets/img/next.svg'
import useHandleError from '../useHandleError'
import { accessTokenAtom } from '../../customHooks/auth'
import { useAtom } from 'jotai'

type Props = {
    setSelectedDay: Dispatch<SetStateAction<Date>>
    selectedDay: Date
    type: string
}

export default function Calendar({ setSelectedDay, selectedDay, type }: Props) {
    const { t } = useTranslation(['booking'])
    const [selectedMonth, setSelectedMonth] = useState<Date>(new Date())
    const [days, setDays] = useState<number[]>([])
    const [accessToken,] = useAtom(accessTokenAtom)

    const { isError, data, error } = useQuery<AppointmentForMonth[], Error>(
        [
            `appointsForMonth`,
            {
                accessToken,
                year: selectedMonth.getFullYear(),
                month: selectedMonth.getMonth() + 1,
            },
        ],
        () =>
            getAppointmentsCountForMonth(
                accessToken,
                selectedMonth.getFullYear(),
                selectedMonth.getMonth() + 1,
            ),
        { retry: 1 },
    )

    useEffect(() => {
        const firstDay = new Date(
            selectedMonth.getFullYear(),
            selectedMonth.getMonth(),
            1,
        )
        const lastDay = new Date(
            selectedMonth.getFullYear(),
            selectedMonth.getMonth() + 1,
            0,
        )

        let tmpDays: number[] = []
        let offset: number = firstDay.getDay() === 0 ? 7 : firstDay.getDay()

        for (let i = firstDay.getDate(); i <= lastDay.getDate(); i++) {
            if (i === firstDay.getDate()) {
                for (let j = 1; j < offset; j++) {
                    tmpDays.push(-1)
                }
            }
            tmpDays.push(i)
        }

        setDays(() => tmpDays)
    }, [selectedMonth])

    const previousMonth = () => {
        const newDate = new Date(
            selectedMonth.getFullYear(),
            selectedMonth.getMonth() - 1,
            1,
            8,
            0,
            0,
        )
        setSelectedMonth(newDate)
        setSelectedDay(newDate)
    }

    const nextMonth = () => {
        const newDate = new Date(
            selectedMonth.getFullYear(),
            selectedMonth.getMonth() + 1,
            1,
            8,
            0,
            0,
        )
        setSelectedMonth(newDate)
        setSelectedDay(newDate)
    }

    useHandleError(isError, error)

    if (isError) {
        if (error instanceof Error) {
            return <ErrorFallback error={error} />
        }
        return <ErrorFallback error={{ message: 'Error' }} />
    }

    return (
        <ErrorBoundary>
            <div className="grid grid-cols-1 gap-4 font-[BJEAvertaBold]">
                <div className="text-white bg-bje-primary md:rounded-t-lg">
                    <div className="flex items-center gap-2 p-4">
                        <div>
                            <img
                                src={previousIcon}
                                alt="<"
                                onClick={previousMonth}
                                className="cursor-pointer"
                            />
                        </div>
                        <div className="text-lg text-center text-bold grow">
                            {format(selectedMonth, 'MMMM yyyy', {
                                locale: de,
                            })}
                        </div>
                        <div className="text-right">
                            <img
                                src={nextIcon}
                                alt=">"
                                onClick={nextMonth}
                                className="cursor-pointer"
                            />
                        </div>
                    </div>
                </div>
                <div className="flex-none text-lg p-6 pt-0">
                    <div className="grid grid-cols-7 gap-2">
                        {['M', 'D', 'M', 'D', 'F', 'S', 'S'].map((label, index) => {
                            return (
                                <div
                                    key={`label-${index}`}
                                    className="text-center text-bje-dark-grey font-[BJEAverta]"
                                >
                                    {label}
                                </div>
                            )
                        })}
                        {days && Array.isArray(days)
                            ? days.map((day: number, index: number) => {
                                const countFree =
                                    data && data[day - 1]?.countFree
                                        ? data[day - 1]?.countFree
                                        : 0
                                const countTickets =
                                    data && data[day - 1]?.countTickets
                                        ? data[day - 1]?.countTickets
                                        : 0

                                const handleClick = () => {
                                    if (type === 'view' || countFree > 0) {
                                        setSelectedDay(
                                            new Date(
                                                selectedMonth.getFullYear(),
                                                selectedMonth.getMonth(),
                                                day,
                                                8,
                                                0,
                                                0,
                                            ),
                                        )
                                    }
                                }

                                if (day === -1) {
                                    return <div key={`${day}.${index}`} />
                                }

                                return (
                                    <div
                                        key={`${day}`}
                                        className={clsx(
                                            'flex flex-col p-2 text-sm md:text-base rounded-lg cursor-pointer justify-items-center',
                                            type === 'booking' && countFree === 0
                                                ? 'bg-bje-light-grey text-bje-medium-grey'
                                                : day == selectedDay.getDate()
                                                    ? 'bg-white text-bje-blue border-2 border-bje-primary'
                                                    : 'bg-sky-100 text-black',
                                        )}
                                        onClick={handleClick}
                                    >
                                        <div className="self-center">
                                            {`0${day}`.slice(-2)}
                                        </div>
                                        <div className="flex-none text-sm md:text-base text-center">
                                            {type === 'booking'
                                                ? t('count free', {
                                                    count: countFree,
                                                })
                                                : t('count tickets', {
                                                    count: countTickets,
                                                })}
                                        </div>
                                    </div>
                                )
                            })
                            : null}
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    )
}
