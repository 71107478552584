import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, useWatch } from 'react-hook-form'
import { formatISO, isEqual, isAfter } from 'date-fns'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from '@tanstack/react-location'
import clsx from 'clsx'
import toast from 'react-hot-toast'
import { useQueryClient, useQuery, useMutation } from 'react-query'

import ErrorBoundary from '../ErrorBoundary'
import {
    userAtom,
    lastInsertedAtom,
    consentApprovalDialogAtom,
} from '../Jotai'
import ConsentApprovalDialog from '../ConsentApprovalDialog'

import { SelectedAppointmentType, Ticket, User } from '../../types'
import { createAppointment, getConsentApproval } from '../../utils/api'

import { useAtom, useSetAtom } from 'jotai'
import { accessTokenAtom } from '../../customHooks/auth'



const AppointFormInput = z.object({
    companyName: z.string().min(1, { message: 'company error' }),
    companyAddress: z.string().min(1, { message: 'company error' }),
    companyPostalcode: z.string().min(1, { message: 'company error' }),
    companyCity: z.string().min(1, { message: 'company error' }),
    salutation: z.string().min(1, { message: 'salutation error' }),
    firstname: z.string().min(1, { message: 'firstname error' }),
    lastname: z.string().min(1, { message: 'lastname error' }),
    email: z.string().email({ message: 'email error' }),
    phone: z.string().min(1, { message: 'phone error' }),
    comment: z.string().optional(),
    authority: z.string().optional(),
    targetGroup: z.string().optional(),
    kinds: z.string().optional(),
    consentApproved: z.literal(true),
})

const AppointmentsType = z
    .array(z.object({ begin: z.date(), end: z.date() }))
    .min(1)

type Props = {
    selectedAppointments: SelectedAppointmentType[]
    selectedDay: Date
}

export default function AppointForm({
    selectedAppointments,
    selectedDay,
}: Props) {
    const navigate = useNavigate()
    const [hasError, setHasError] = useState<string | null>(null)
    const { t } = useTranslation(['booking'])
    const [user,] = useAtom(userAtom)
    const [accessToken,] = useAtom(accessTokenAtom)
    const setLastInserted = useSetAtom(lastInsertedAtom)
    const [loading, setLoading] = useState(false)
    const setIsOpenConsentApprovalDialog = useSetAtom(
        consentApprovalDialogAtom,
    )

    const hasConsentApproved = useQuery(
        [`consentApproval`, { accessToken }],
        () => getConsentApproval(accessToken),
        {
            retry: 0,
        },
    )

    const defaultValues = {
        companyName: user?.company?.name || '',
        companyAddress: user?.company?.address || '',
        companyPostalcode: user?.company?.postalcode || '',
        companyCity: user?.company?.city || '',
        salutation: user?.salutation || 'Herr',
        firstname: user?.firstname || t('invite surname'),
        lastname: user?.lastname || t('invite name'),
        email: user?.email || t('invite email'),
        phone: user?.phone || t('phone'),
        comment: t('booking_hint'),
        authority: '',
        targetGroup: user?.company?.target_group || '',
        kinds: user?.kinds,
        consentApproved: false,
    }


    const {
        register,
        handleSubmit,
        control,
        reset,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(AppointFormInput),
        defaultValues,
    })

    const consentApproved = useWatch({
        control,
        name: 'consentApproved',
    })

    useEffect(() => {
        if (user) {
            reset({
                companyName: user.company?.name || 'Test',
                companyAddress: user.company?.address || 'Test',
                companyPostalcode: user.company?.postalcode || '12345',
                companyCity: user.company?.city || 'Test',
                salutation: user.salutation || 'Herr',
                firstname: user.firstname || 'Test',
                lastname: user.lastname || 'Test',
                email: user.email || 'test@test.de',
                phone: user.phone || '1234',
                comment: t('booking_hint'),
                authority: user.company?.authority?.toString(),
                targetGroup: user.company?.target_group || '',
                kinds: user.kinds,
                consentApproved: hasConsentApproved.data,
            })
        }
    }, [user, hasConsentApproved.data])

    const queryClient = useQueryClient()

    const mutation = useMutation(
        (newAppointments: Ticket[]) =>
            Promise.all(
                newAppointments.map((appointmentData: Ticket) =>
                    createAppointment(accessToken, appointmentData),
                ),
            ),
        {
            onSuccess: (data: Ticket[]) => {
                setLastInserted(data)
                queryClient.invalidateQueries([
                    `appointsForMonth`,
                    { year: selectedDay.getFullYear(), month: selectedDay.getMonth() + 1 },
                ])
                queryClient.invalidateQueries([
                    `getTimesForDate`,
                    { date: formatISO(selectedDay) },
                ])
                queryClient.invalidateQueries(`appointmentsForDate`)
                queryClient.invalidateQueries(`ownAppointments`)
                navigate({ to: '/bookingResult' })
            },
            onError: () => {
                toast(t('error create appointment'))
            },
            onSettled: () => {
                setLoading(false)
            },
        },
    )

    const onSubmit = handleSubmit(data => {
        try {
            AppointmentsType.parse(selectedAppointments)
            setHasError(null)
            setLoading(true)

            const now = new Date()

            if (selectedAppointments.find(el => isAfter(now, el.end))) {
                throw new Error('date in past')
            }
            const reducedAppointments: SelectedAppointmentType[] =
                selectedAppointments.reduce(
                    (a: SelectedAppointmentType[], v: SelectedAppointmentType) => {
                        const index = a.findIndex(el => isEqual(el.end, v.begin))

                        if (index > -1) {
                            const b = a
                            b[index].end = v.end
                            return b
                        }

                        return [...a, v]
                    },
                    [],
                )

            mutation.mutate(
                reducedAppointments.map((appointment: SelectedAppointmentType) => {
                    const newUser: User = {
                        bje_username: user.bje_username,
                        bje_email: user.bje_email,
                        company: {
                            name: data.companyName,
                            address: data.companyAddress,
                            postalcode: data.companyPostalcode,
                            city: data.companyCity,
                        },
                        salutation: data.salutation,
                        firstname: data.firstname,
                        lastname: data.lastname,
                        email: data.email,
                        phone: data.phone,
                        authority: data.authority?.toString(),
                        target_group: data.targetGroup,
                        kinds: data.kinds,
                    }

                    return {
                        begin: formatISO(appointment.begin),
                        end: formatISO(appointment.end),
                        comment: data.comment,
                        consentApproved: data.consentApproved,
                        user: newUser,
                        kinds: data.kinds
                    }
                }),
            )
        } catch (e) {
            setHasError(typeof e === 'string' ? e : 'true')
        }
    })

    const handleOpenConsenrApprovalDialog = (
        e: React.MouseEvent<HTMLElement>,
    ) => {
        e.preventDefault()
        e.stopPropagation()
        setIsOpenConsentApprovalDialog(true)
    }

    const fieldClasses = 'w-full rounded input h-12 px-4 text-sm bg-bje-light-grey text-bje-dark-grey font-bold'
    const errorClasses = 'border-0 border-b-2 border-error'

    return (
        <ErrorBoundary>
            <div className="grid grid-cols-1 gap-2 p-4 md:p-0">
                <div className="border-2 p-4 rounded-lg border-bje-light">
                    <h4 className="text-2xl text-bje-primary mb-4">{t('company')}</h4>
                    <div className="grid gap-2 md:gap-0 md:grid-cols-2">
                        <div className="grid grid-cols-1">
                            <span className="text-sm font-[BJEAvertaBold]">{t('companyName')}:</span>
                            {user.company.name}
                        </div>
                        <div className="grid grid-cols-1">
                            <span className="text-sm font-[BJEAvertaBold]">{t('companyAddress')}:</span>
                            <span>{user.company.address}</span>
                            <span>{user.company.postalcode} {user.company.city}</span>
                        </div>
                    </div>
                </div>
            </div>
            <form onSubmit={onSubmit}>
                <input
                    type="hidden"
                    {...register('companyName')} // eslint-disable-line react/jsx-props-no-spreading
                />
                <input
                    type="hidden"
                    {...register('companyAddress')} // eslint-disable-line react/jsx-props-no-spreading
                />
                <input
                    type="hidden"
                    {...register('companyPostalcode')} // eslint-disable-line react/jsx-props-no-spreading
                />
                <input
                    type="hidden"
                    {...register('companyCity')} // eslint-disable-line react/jsx-props-no-spreading
                />
                <input
                    type="hidden"
                    {...register('authority')} // eslint-disable-line react/jsx-props-no-spreading
                />
                <input
                    type="hidden"
                    className={clsx(
                        fieldClasses,
                    )}
                    {...register('targetGroup')} // eslint-disable-line react/jsx-props-no-spreading
                />
                <h4 className="text-2xl pt-7 pb-4 p-4 md:pl-0">{t('contact person')}</h4>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-5 p-4 md:p-0">
                    <div className="w-full form-control">
                        <select
                            className={clsx(
                                fieldClasses,
                                errors.salutation?.message ? errorClasses : '',
                                user.salutation ? 'bg-white border-bje-primary text-black' : ''
                            )}
                            {...register('salutation')} // eslint-disable-line react/jsx-props-no-spreading
                        >
                            <option>Herr</option>
                            <option>Frau</option>
                        </select>
                        {errors.salutation?.message && (
                            <p className="pt-1 text-error">
                                {t(errors.salutation?.message)}
                            </p>
                        )}
                    </div>
                    <div className="w-full form-control">
                        <input
                            type="text"
                            placeholder={t('invite surname')}
                            className={clsx(
                                fieldClasses,
                                errors.firstname?.message ? errorClasses : '',
                                user.firstname ? 'bg-white border-bje-primary text-black' : ''
                            )}
                            {...register('firstname')} // eslint-disable-line react/jsx-props-no-spreading
                        />
                        {errors.firstname?.message && (
                            <p className="pt-1 text-error">
                                {t(errors.firstname?.message)}
                            </p>
                        )}
                    </div>
                    <div className="w-full form-control">
                        <input
                            type="text"
                            placeholder={t('invite name')}
                            className={clsx(
                                fieldClasses,
                                errors.lastname?.message ? errorClasses : '',
                                user.lastname ? 'bg-white border-bje-primary text-black' : ''
                            )}
                            {...register('lastname')} // eslint-disable-line react/jsx-props-no-spreading
                        />
                        {errors.lastname?.message && (
                            <p className="pt-1 text-error">{t(errors.lastname?.message)}</p>
                        )}
                    </div>
                    <div className="w-full form-control">
                        <input
                            type="text"
                            placeholder={t('phone')}
                            className={clsx(
                                fieldClasses,
                                errors.phone?.message ? errorClasses : '',
                                user.phone ? 'bg-white border-bje-primary text-black' : ''
                            )}
                            {...register('phone')} // eslint-disable-line react/jsx-props-no-spreading
                        />
                        {errors.phone?.message && (
                            <p className="pt-1 text-error">{t(errors.phone?.message)}</p>
                        )}
                    </div>
                    <div className="w-full md:col-span-2 form-control">
                        <input
                            type="text"
                            placeholder={t('invite email')}
                            className={clsx(
                                fieldClasses,
                                errors.email?.message ? errorClasses : '',
                                user.email ? 'bg-white border-bje-primary text-black' : ''
                            )}
                            {...register('email')} // eslint-disable-line react/jsx-props-no-spreading
                        />
                        {errors.email?.message && (
                            <p className="pt-1 text-error">{t(errors.email?.message)}</p>
                        )}
                    </div>
                </div>
                <div className="text-2xl py-6 font-[BJEAvertaBold] p-4 md:pl-0">{t('comment')}</div>
                <div className="form-control mb-8 p-4 md:p-0">
                    <textarea
                        onFocus={(e) => {
                            if (e.target.value === t('booking_hint')) {
                                setValue("comment", "")
                            }
                        }}
                        className={clsx(
                            fieldClasses,
                            errors.comment?.message ? errorClasses : '',
                            'py-4 px-8'
                        )}
                        {...register('comment')} // eslint-disable-line react/jsx-props-no-spreading
                    />
                    {errors.comment?.message && (
                        <p className="pt-1 text-error">{t(errors.comment?.message)}</p>
                    )}
                </div>
                <div>
                    <div className="form-control w-fit pb-6 p-4 md:p-0">
                        <label className="cursor-pointer label">
                            <input
                                type="checkbox"
                                className={clsx(
                                    'checkbox mr-12 md:mr-4 border-2 border-bje-light',
                                    errors.consentApproved?.message
                                        ? 'border-2 border-error'
                                        : `checkbox-primary`,
                                )}
                                {...register('consentApproved')} // eslint-disable-line react/ }
                            />
                            <span className="text-base label-text">
                                {t('consent approved 1')}{' '}
                                <span
                                    onClick={handleOpenConsenrApprovalDialog}
                                    className="text-black font-[BJEAvertaBold] underline"
                                >
                                    {t('consent approved 2')}
                                </span>{' '}
                                {t('consent approved 3')}
                            </span>
                        </label>
                        {errors.consentApproved?.message && (
                            <p className="pt-1 text-error">
                                {t('consent approved error')}
                            </p>
                        )}
                    </div>
                </div>
                <div className="form-control mb-12 md:mb-0 items-center">
                    {hasError && (
                        <p className="py-1 text-error">
                            {t(hasError !== 'true' ? hasError : 'no appointments')}
                        </p>
                    )}
                    <button
                        disabled={!consentApproved}
                        type="submit"
                        className={clsx(
                            'btn btn-primary md:w-full w-3/4',
                            loading ? 'loading' : '',
                            '!font-[BJEAvertaBold] normal-case'
                        )}
                    >
                        {t('submit')}
                    </button>
                </div>
                <ConsentApprovalDialog />
            </form>
        </ErrorBoundary >
    )
}
