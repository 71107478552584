import { useTranslation } from 'react-i18next'
import { Link } from '@tanstack/react-location'
import { useAtom, useAtomValue } from 'jotai'
import { userAtom } from '../Jotai/index'

import logo from '../../../assets/img/re-design/Header_Navigation/Logo/Logo.svg'
import userIcon from '../../../assets/img/re-design/userIcon.svg'
import { accessTokenAtom } from '../../customHooks/auth'
import { useContext, useEffect, useState } from 'react'

import MyBjePanel from './MyBJEPanel'
import BurgerMenu from './BurgerMenu'
import { MobileContext } from '../..'

function Menu() {
    const { t } = useTranslation(['home'])
    const user = useAtomValue(userAtom)
    const [accessToken] = useAtom(accessTokenAtom)

    const isMobile = useContext(MobileContext)

    const defaultNavItems = [
        {
            to: "/booking",
            label: "book an appointment"
        },
        {
            to: "/useFullInformation",
            label: "useful information"
        },
        {
            to: "/ownAppointment",
            label: "my dates"
        }
    ]

    const [navItems, setNavItems] = useState(defaultNavItems)

    useEffect(() => {
        if (user?.rights?.includes('canSeeBookingCalendar')) {
            setNavItems(prev => [...prev, { to: "/bookingCalendar", label: "booking calendar" }])
        }

        if (user?.rights?.includes('setRights')) {
            setNavItems(prev => [...prev, { to: "/rights", label: "right management" }])

        }
    }, [user])

    return (
        <header className="sticky top-0 z-[999] bg-white">
            <div className="flex items-center justify-between p-6">
                <div>
                    <a href="/" title="Zur Startseite">
                        <img className="h-[40px] md:h-[60px]" src={logo} />
                    </a>
                </div>
                {!isMobile ?
                    <>
                        <div className="main-navigation" id="mainNavigation" data-flyout-menu="true">
                            <nav className="flex gap-x-6" itemScope itemType="http://schema.org/SiteNavigationElement">
                                {navItems.map(item =>
                                    <Link to={item.to} key={item.to} className="cursor-pointer p-4">
                                        {({ isActive }) => (
                                            <span className={isActive ? 'relative active-nav font-[BJEAvertaBold]' : 'relative font-[BJEAvertaBold]'}>
                                                {t(item.label)}
                                            </span>
                                        )}
                                    </Link>
                                )}
                            </nav>
                        </div>
                        <div className="justify-content-center pr-[120px]">
                            <MyBjePanel
                                icon={userIcon}
                                client_id={process.env.SSO_CLIENT_ID as string}
                                token={accessToken}
                                provider={process.env.SSO_PROVIDER as string}
                            />
                        </div></>
                    :
                    <div className="justify-content-end">
                        <BurgerMenu items={navItems} />
                    </div>
                }
            </div>
        </header>
    )
}

export default Menu
