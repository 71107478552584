import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import useHandleError from '../../components/useHandleError'
import ErrorBoundary, { ErrorFallback } from '../../components/ErrorBoundary'
import ListAppointments from '../../components/ListAppointments'

import { AppointmentRespone } from '../../types'
import { getAppointments } from '../../utils/api'
import { useAtom } from 'jotai'
import { accessTokenAtom } from '../../customHooks/auth'

import LoadingIndicator from '../../components/LoadingIndicator'

import ReactPaginate from 'react-paginate'

export default function App() {
    const { t } = useTranslation(['ownAppointment'])
    const pageSize = 10

    const [page, setPage] = useState<number>(1)
    const [showPast, setShowPast] = useState<boolean>(false)
    const [accessToken,] = useAtom(accessTokenAtom)

    const { isLoading, isError, data, error } = useQuery<AppointmentRespone, Error>(
        [`ownAppointments`, { accessToken, pageSize, page, showPast }],
        () => getAppointments(accessToken, page, pageSize, showPast),
        {
            retry: 0,
            staleTime: Infinity  // only fetch once
        },
    )

    useHandleError(isError, error)

    if (isLoading) {
        return <LoadingIndicator />
    }

    if (isError) {
        if (error instanceof Error) {
            return <ErrorFallback error={error} />
        }
        return <ErrorFallback error={{ message: 'Error' }} />
    }

    const paginationButtonCls = "btn btn-primary min-h-0 h-8 rounded-md"

    return (
        <ErrorBoundary>
            <div className="flex flex-col h-full gap-4 content">
                <div>
                    <h1 className="font-[BJEAvertaBold]">
                        {t('title 2')}
                    </h1>
                </div>
                <div className="form-control">
                </div>
                <div className="grow">
                    <ListAppointments data={data?.tickets}
                        actions={{
                            page: setPage,
                            setShowPast: setShowPast
                        }}
                        showPast={showPast}
                        type="own"
                    />
                </div>
                <div className="grid grid-cols-2">
                    {data && data.pages > 0 ?
                        <>
                            <div>
                                <Trans ns="ownAppointment" i18nKey="page_shown" values={{ current: page, total: data?.pages || 1 }} />
                            </div>
                            <ReactPaginate
                                pageLinkClassName={paginationButtonCls + " bg-white text-bje-primary hover:bg-bje-dark-grey hover:border-white hover:text-bje-light-grey"}
                                activeLinkClassName={paginationButtonCls + " !bg-bje-primary !text-white"}
                                previousLinkClassName={page > 1 ? paginationButtonCls + " bg-white text-bje-primary hover:text-white" : 'hidden'}
                                nextLinkClassName={page == (data?.pages || 1) ? "hidden" : paginationButtonCls + " bg-white text-bje-primary hover:text-white"}
                                className="flex gap-4 p-2 justify-end"
                                nextLabel=">"
                                previousLabel="<"
                                forcePage={page - 1}
                                onPageChange={(e) => setPage(e.selected + 1)}
                                pageCount={data?.pages || 1} />
                        </> : <></>}
                </div>

            </div>
        </ErrorBoundary>
    )
}
