import { useTranslation } from 'react-i18next'

import Footer from '../../components/Footer'
import logo from '../../../assets/img/re-design/Header_Navigation/Logo/Logo.svg';

import '../../styles/page.no-perm.css'

export default function() {

    const { t } = useTranslation(["denied"])
    return <><div id="no-perm_content">
        <nav id="no-perm_top-nav" className="py-2 pr-0 lg:py-0">
            <img width="169px" src={logo} />
        </nav>
        <div className="w-full">
            <div className="hero-img">
                <p id="hero-headline_container">
                    <h2>Köln</h2>
                    <h1>Smarter Gallery_</h1>
                </p>
            </div>
            <div className="q-hero-content relative top-[110px]">
                <div className="hero-blue-box absolute bottom-0 p-[65px]">
                    <div className="mx-auto">
                        <h4 className="light py-10">
                            {t('headline')}
                        </h4>
                        <div className="font-light mb-4">
                            <p>
                                {t('noperm-text')}
                            </p>
                            <p>
                                {t('adm-hint')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <Footer />
    </>
}
