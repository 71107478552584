import { Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'
import { acceptDialogAtom } from '../Jotai'
import arrow from '../../../assets/img/Arrow.svg'
import SVG from 'react-inlinesvg'
import ErrorBoundary from '../ErrorBoundary'
import close from '../../../assets/img/close.svg'

export default function AcceptDialog() {
  const { t } = useTranslation(['acceptDialog'])
  const [isOpen, setIsOpen] = useAtom(acceptDialogAtom)

  const closeModal = () => {
    setIsOpen(undefined)
  }

  const handleAccept = () => {
    if (isOpen) {
      isOpen.accept(undefined)
    }
    closeModal()
  }

  return (
    <ErrorBoundary>
      <Transition
        show={!!isOpen}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed inset-0 bg-black opacity-50"
          style={{ zIndex: 1000 }}
        />
      </Transition>
      <Transition
        show={!!isOpen}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed w-5/6 p-6 overflow-hidden text-left align-middle transition-all transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-xl top-1/2 left-1/2"
          style={{ zIndex: 1001, maxWidth: '1920px' }}
        >
          <div className="flex gap-2 pb-16 text-3xl text-bje-primary">
            <span className="flex-1">
              {t(
                isOpen?.title || 'acceptdialog title',
                //@ts-ignore
                isOpen?.titleProps || {},
              )}
            </span>
            <img
              src={close}
              onClick={closeModal}
              className="cursor-pointer"
            />
          </div>
          {t(
            isOpen?.subtitle || 'acceptdialog subtitle',
            //@ts-ignore
            isOpen?.subtitleProps || {},
          )}
          <div className="flex justify-end gap-2 pt-16">
            <button
              type="button"
              onClick={handleAccept}
              className="h-12 px-4 text-sm rounded-none btn btn-primary"
            >
              <div className="flex items-center gap-2">
                <div>
                  <SVG src={arrow} width={24} />
                </div>
                <div>{t(isOpen?.buttonLabel || 'acceptdialog accept')}</div>
              </div>
            </button>
          </div>
        </div>
      </Transition>
    </ErrorBoundary>
  )
}