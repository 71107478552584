import React from 'react'
import ErrorBoundary from '../ErrorBoundary'

type Props = {
    text: string
    icon: any
    onClick: (event: React.MouseEvent<HTMLElement>) => void
}

export default function QCard({ text, icon, onClick }: Props) {
    return (
        <ErrorBoundary>
            <button className="alert flex-row relative rounded-lg bg-white border-2 border-bje-light py-[18px] px-8 w-80" onClick={onClick}>
                <span className="font-[BJEAvertaBold] text-lg">{text}</span>
                <div>
                    <span className="absolute top-0 mt-[-2px] g-primary bg-primary h-1 w-[34px]">&nbsp;</span>
                    <img className="w-[34px]" src={icon} alt={`icon_${text}`} />
                </div>
            </button>
        </ErrorBoundary>
    )
}
