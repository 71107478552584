import React, { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { useAtom } from 'jotai'
import { z } from 'zod'
import { format } from 'date-fns' // eslint-disable-line import/no-duplicates
import deLocale from 'date-fns/locale/de' // eslint-disable-line import/no-duplicates
import SVG from 'react-inlinesvg'
import { inviteDialogAtom } from '../Jotai'
import arrow from '../../../assets/img/Arrow.svg'
import ErrorBoundary from '../ErrorBoundary'
import close from '../../../assets/img/close.svg'

export default function Invite() {
  const { t } = useTranslation(['booking'])
  const [isOpen, setIsOpen] = useAtom(inviteDialogAtom)

  const closeModal = () => {
    setIsOpen(undefined)
  }

  const date =
    isOpen && isOpen[0] && isOpen[0].begin ? new Date(isOpen[0].begin) : null

  const user = isOpen && isOpen[0] ? isOpen[0].user : null

  const datesStr = isOpen
    ? isOpen
      .map(appointment => {
        try {
          const beginDate = z.date().parse(new Date(appointment?.begin))
          const endDate = z.date().parse(new Date(appointment?.end))
          return t('invite date', {
            begin: format(beginDate, 'HH:mm', {
              locale: deLocale,
            }),
            end: format(endDate, 'HH:mm', { locale: deLocale }),
          })
        } catch (e) {
          return ''
        }
      })
      .join('\n')
    : ''

  const mailSubject = t('invite subject', { firma: user?.company?.name })
  const mailText = t('invite mail', {
    date: date ? format(date, 'dd.MM.uuuu') : '',
    dates: datesStr,
    userSalutation: user?.salutation,
    userFirstname: user?.firstname,
    userLastname: user?.lastname,
    firma: user?.company?.name,
  })

  return (
    <ErrorBoundary>
      <Transition
        show={!!isOpen}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed inset-0 bg-black opacity-50"
          style={{ zIndex: 1000 }}
        />
      </Transition>
      <Transition
        show={!!isOpen}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed z-50 w-full max-h-screen p-6 overflow-auto text-left align-middle transition-all transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-xl md:w-2/3 xl:w-1/3 top-1/2 left-1/2"
          style={{ zIndex: 1001, maxWidth: '1920px' }}
        >
          <div className="flex gap-2 pb-16 text-3xl text-bje-primary">
            <span className="flex-1">
              {t('invite title', {
                date: date ? format(date, 'dd.MM.uuuu') : '',
              })}
            </span>
            <button type="button" onClick={closeModal}>
              <img src={close} alt="close" />
            </button>
          </div>
          <div>{t('invite text')}</div>
          <div className="flex flex-col-reverse justify-end gap-2 pt-16 md:flex-row">
            <button
              type="button"
              onClick={closeModal}
              className="h-12 px-4 text-sm rounded-none btn btn-outline btn-primary"
            >
              <div className="flex items-center gap-2">
                <div>
                  <SVG src={arrow} width={24} />
                </div>
                <div>{t('invite cancel')}</div>
              </div>
            </button>
            <a
              className="h-12 px-4 text-sm rounded-none btn btn-primary"
              href={`mailto:?subject=${encodeURIComponent(
                mailSubject,
              )}&body=${encodeURIComponent(mailText)}`}
            >
              <div className="flex items-center gap-2">
                <div>
                  <SVG src={arrow} width={24} />
                </div>
                <div>{t('invite submit')}</div>
              </div>
            </a>
          </div>
        </div>
      </Transition>
    </ErrorBoundary>
  )
}
