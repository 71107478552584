import {
    MakeGenerics,
    Outlet,
    ReactLocation,
    createHashHistory,
    Router,
    Route,
    parseSearchWith,
    stringifySearchWith
} from '@tanstack/react-location'

import {
    AuthorizedPages,
    Home,
    Booking,
    BookingResult,
    BookingCalendar,
    OwnAppointments,
    UsefullInformation,
    RightManagement,
    NoPerms
} from './pages'

import Footer from './components/Footer'
import ErrorBoundary from './components/ErrorBoundary'
import AcceptDialog from './components/AcceptDialog'
import Notifications from './components/Notifications'
import Header from './components/Header'

import './utils/i18n/config'
import './styles/fonts.css'
import './styles/redesign.css'
import './styles/tailwind.css'
import './styles/swiper.css'

import { accessTokenAtom, AuthProvider } from './customHooks/auth'
import { RBACRequest } from '@bjep-extmod/permission-request'
import LoadingIndicator from './components/LoadingIndicator'
import { createContext, useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import i18next from 'i18next'

export const MobileContext = createContext(window.innerWidth <= 768)

const hashHistory = createHashHistory()

export type LocationGenerics = MakeGenerics<{
    LoaderData: {}
    Search: {
        pagination: {
            pageSize?: number
            page?: number
        }
    }
}>

const location = new ReactLocation<LocationGenerics>({
    parseSearch: parseSearchWith(JSON.parse),
    stringifySearch: stringifySearchWith(JSON.stringify),
    history: hashHistory,
})


export default function App() {
    // re-toggle mobile check
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

    const mobileEvent = () => {
        setIsMobile(window.innerWidth <= 768)
    }

    useEffect(() => {
        window.addEventListener('resize', mobileEvent)
        return () => {
            window.removeEventListener('resize', mobileEvent);
        }
    }, []);

    // use as hotfix to toggle languages
    i18next.changeLanguage("de")
    const routes: Route<LocationGenerics>[] = [
        // Public non authorized routes
        { path: "/authorization-required", element: <NoPerms /> },
        // Privates Routes
        {
            path: "", element: <AuthorizedPages />, children: [
                { path: '/', element: <Home /> },
                { path: 'booking', element: <Booking />, },
                { path: 'bookingCalendar', element: <BookingCalendar /> },
                { path: 'bookingResult', element: <BookingResult /> },
                { path: 'ownAppointment', element: <OwnAppointments />, },
                { path: 'usefullInformation', element: <UsefullInformation /> },
                { path: 'rights', element: <RightManagement /> },
            ],
        }
    ]

    const [checkedPermissions, setCheckedPermissions] = useState(false)
    const [hasUsagePermission, setHasUsagePermissions] = useState(false)
    const [accessToken, setAccessToken] = useAtom(accessTokenAtom)


    useEffect(() => {
        if (!accessToken || accessToken == "refresh") {
            return
        }

        if (checkedPermissions) {
            return
        }

        const rbac = new RBACRequest(process.env.SSO_PROVIDER as string)
        rbac.can(
            accessToken,
            "smarter-gallery",
            "use",
            false,
            "mail",
            "smarter_gallery"
        ).then(res => {
            if (res) {
                setHasUsagePermissions(res.getValue())
            }
            setCheckedPermissions(true)
        }).catch(err => {
            if (err.code === 2) {
                // Auth failure
                // needs to refresh accessToken
                // will be done in the AuthProvider if refresh token is set and access token signals it
                setAccessToken("refresh")
            }
        })
    }, [accessToken])

    return (
        !accessToken || accessToken == "refresh" ? <AuthProvider> <LoadingIndicator /></AuthProvider> :
            !checkedPermissions ? <LoadingIndicator /> :
                !hasUsagePermission ? <MobileContext.Provider value={isMobile}><NoPerms /></MobileContext.Provider> :
                    <AuthProvider>
                        <MobileContext.Provider value={isMobile}>
                            <ErrorBoundary>
                                <div data-theme="light" className="h-full">
                                    <Router location={location} routes={routes}>
                                        <Header />
                                        <Outlet />
                                    </Router>
                                    <AcceptDialog />
                                    <Notifications />
                                    <Footer />
                                </div>
                            </ErrorBoundary>
                        </MobileContext.Provider>
                    </AuthProvider>
    )
}
