import { useTranslation } from 'react-i18next'
import SVG from 'react-inlinesvg'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css/bundle';

// import required modules
import { Pagination } from 'swiper/modules';

import ErrorBoundary from '../../components/ErrorBoundary'

import downloadIcon from '../../../assets/icons/download.svg'
import arrowRight from '../../../assets/icons/arrow-right.svg'

import ImageOverlay from './imageOverlay';
import { imageOverlayOpened } from '../../components/Jotai'
import { useAtom } from 'jotai'
import Card from './InfoCard'
import { useContext } from 'react'
import { MobileContext } from '../..'

export interface POI extends Restaurant, Hotel {
    headline: string
    distance: string
    address: string
}

type Restaurant = {
    phone: string
    openings: string
    type: string
}

type Hotel = {
    parking: string
    price: string
    mail: string
}

const UsefullInformation = () => {

    const { t, i18n } = useTranslation(['usefullInformation'])
    const [viewImages, setViewImages] = useAtom(imageOverlayOpened)
    const transData = (i18n.getDataByLanguage(i18n.language) as any)?.usefullInformation
    const isMobile = useContext(MobileContext)

    return <ErrorBoundary>
        <ImageOverlay />
        <main className="w-5/6 m-auto">
            <header className="mb-4"><h1 className="text-3xl">{t('title 2')}</h1></header>
            <div className="md:flex md:gap-20">
                <div className="w-full md:w-1/3">
                    <div className="border-2 rounded-lg bg-bje-light">
                        <aside className="p-6 bg-white rounded-t-md">
                            <h4 className="text-sm text-bje-blue font-[BJEAvertaLight] tracking-[0.45rem]">QUICK LINKS</h4>
                            <section className="grid gap-3 my-3">
                                <h5 className="text-lg">{t('address title')}</h5>
                                <p className="mb-2">
                                    Smarter Gallery by BUSCH-JAEGER
                                    <br />
                                    <br />
                                    Anna-Schneider Steig 9
                                    <br />
                                    50678 Köln
                                </p>
                                <a
                                    className="font-[BJEAvertaBold] border-b-2 border-bje-blue pb-4 flex justify-between"
                                    href="https://maps.google.com/maps?ll=50.924355,6.967219&z=15&t=m&hl=de&gl=DE&mapclient=embed&cid=15588651089053346266"
                                    target="_blank"
                                    rel="noreferrer">
                                    {t('open_gmaps')}
                                    <SVG src={arrowRight} />
                                </a>
                            </section>
                        </aside>
                        <aside>
                            <section className="grid gap-3 p-6 ">
                                <h5 className="text-lg">{t('parking facilities title')}</h5>
                                <p className="mb-2">
                                    Tiefgarage Rheinauhafen
                                    <br />
                                    <br />
                                    Harry-Blum-Platz 2
                                    <br />
                                    50678 Köln
                                </p>
                                <a
                                    className="font-[BJEAvertaBold] border-b-2 border-bje-blue pb-4 flex justify-between"
                                    href="https://maps.google.com/maps?ll=50.925618,6.965494&z=15&t=m&hl=de&gl=DE&mapclient=embed&cid=15971574597253792970"
                                    target="_blank"
                                    rel="noreferrer">
                                    {t('open_gmaps')}
                                    <SVG src={arrowRight} />
                                </a>
                                <button
                                    className="font-[BJEAvertaBold] border-b-2 mt-3 border-bje-blue pb-4 flex justify-between"
                                    onClick={() => setViewImages(() => !viewImages)}
                                >
                                    Bilder der Tiefgarage
                                    <SVG src={arrowRight} />
                                </button>
                            </section>
                        </aside>
                    </div>
                    <a
                        href="https://smarter-gallery.busch-jaeger-excellence.de/assets/pdf/BJE_Smarter_Gallery_Koeln_Handbuch.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <button
                            type="button"
                            className="w-full my-3 normal-case btn btn-primary"
                        >
                            <div className="flex items-center gap-2">
                                <SVG src={downloadIcon} />
                                {t('download manual')}
                            </div>
                        </button>
                    </a>
                </div>
                <div className="grid mt-6 md:m-0">
                    <section className="mb-6 md:mb-0">
                        <h5 className="mb-4">{t('furnishing title')}</h5>
                        <dl className="grid gap-6 md:grid-cols-3">
                            <dd className="p-6 rounded-lg bg-bje-light">{t('furnishing 1')}</dd>
                            <dd className="p-6 rounded-lg bg-bje-light">{t('furnishing 2')}</dd>
                            <dd className="p-6 rounded-lg bg-bje-light">{t('furnishing 3')}</dd> </dl>
                    </section>
                    <section>
                        <h5 className="mb-4 md:mt-6">{t('technic title')}</h5>
                        <ul className="p-4 list-disc">
                            <li className="mb-4">
                                {t('technic 1')}
                            </li>
                            <li>
                                {t('technic 2')}
                            </li>
                        </ul>
                    </section>
                    <section>
                        <h5 className="mb-4">{t('catering title')}</h5>
                        <p>{t('catering text')}</p>
                        <div className="grid gap-6 my-6 md:grid-cols-2">
                            <details className="p-2 bj-detail md:p-4 border-y-2 border-bje-blue">
                                <summary className="my-3 text-bje-blue text-sm md:text-base list-none font-[BJEAvertaBold]">Kleines Catering mit Brötchen</summary>
                                <p className="whitespace-pre-line">
                                    Fa. Mauel 1883
                                    Im Zollhafen 22
                                    <br />
                                    50678 Köln
                                    <br />
                                    <br />
                                    Bitte vorab bestellen und bei Abholung bezahlen,
                                    <br />
                                    bei Vorabzahlung auch Lieferung.
                                    <br />
                                    <br />
                                    <strong>Kontakt:</strong>
                                    <br />
                                    Frau Feich oder Frau Hehl
                                    <br />
                                    Telefon: <a className="text-bje-blue" href="tel:+4922120197350">0221- 2019 73 50</a>
                                </p>
                            </details>
                            <details className="p-2 bj-detail md:p-4 border-y-2 border-bje-blue">
                                <summary className="my-3 text-bje-blue text-sm md:text-base list-none font-[BJEAvertaBold]">Metz Catering + Eventmanufaktur</summary>
                                <p>
                                    Hamburger Strasse 6
                                    <br />
                                    50321 Brühl
                                    <br />
                                    <br />
                                    <b>Kontakt:</b>
                                    <br />
                                    E-Mail:  <a className="text-bje-blue" href="mailto:info@metz-event.de">info@metz-event.de</a>
                                    <br />
                                    Telefon: <a className="text-bje-blue" href="tel: +4922327624050">02232 - 762 40 50</a>
                                </p>
                            </details>
                        </div>
                    </section>
                </div>
            </div>
            {!isMobile ?
                <>
                    <section id="poi-restaurants" className="px-16 py-8 overflow-clip card-swiper bg-bje-light">
                        <header className="mb-4">
                            <h1 className="text-2xl">Restaurants in der Nähe</h1>
                        </header>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            breakpoints={{
                                1200: {
                                    slidesPerView: 2,
                                    slidesPerGroup: 2,
                                    spaceBetween: 20
                                },
                                1760: {
                                    slidesPerView: 3,
                                    slidesPerGroup: 3,
                                    spaceBetween: 30
                                },
                                2300: {
                                    slidesPerView: 4,
                                    slidesPerGroup: 4,
                                    spaceBetween: 40
                                }
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                            speed={1000}
                        >
                            {transData?.restaurant && Object.entries(transData?.restaurant).map(restaurant => {
                                return <SwiperSlide>
                                    <Card entity={restaurant[1] as unknown as POI} mobile={isMobile} />
                                </SwiperSlide>
                            })}
                        </Swiper>
                    </section>
                    <section id="poi-hotels" className="px-16 py-8 overflow-clip card-swiper">
                        <header className="mb-4">
                            <h1 className="text-2xl">Hotels</h1>
                        </header>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            breakpoints={{
                                1200: {
                                    slidesPerView: 2,
                                    slidesPerGroup: 2,
                                    spaceBetween: 20
                                },
                                1760: {
                                    slidesPerView: 3,
                                    slidesPerGroup: 3,
                                    spaceBetween: 30
                                },
                                2300: {
                                    slidesPerView: 4,
                                    slidesPerGroup: 4,
                                    spaceBetween: 40
                                }
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                            speed={1000}
                        >
                            {transData?.hotel && Object.entries(transData?.hotel).map(hotel => {
                                return <SwiperSlide>
                                    <Card entity={hotel[1] as unknown as POI} mobile={isMobile} />
                                </SwiperSlide>
                            })}
                        </Swiper>
                    </section>
                </>
                :
                <>
                    <section id="poi-restaurants" className="p-4 bg-bje-light">
                        <header className="mb-4">
                            <h1 className="text-base md:text-2xl">Restaurants in der Nähe</h1>
                        </header>
                        {transData?.restaurant && Object.entries(transData?.restaurant).map(restaurant => {
                            return <Card entity={restaurant[1] as unknown as POI} mobile={isMobile} />
                        })}
                    </section>
                </>
            }

        </main>
    </ErrorBoundary >

}

export default UsefullInformation
