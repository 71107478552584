import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'

const mountPoint = function(elementId: string, clear: boolean) {
    let mount = document.getElementById(elementId)
    if (!mount) {
        mount = document.createElement('div')
        mount.id = elementId
        document.body.appendChild(mount)
        return mount
    }

    if (clear) {
        mount.innerHTML = ''
    }

    return mount
}


const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary(err, info, props) {
        // Customize the root error boundary for your microfrontend here.
        console.error(err.message)
        return <div>Error</div>
    },
    domElementGetter: () => mountPoint('single-spa-smarter-gallery', true)
})

export const { bootstrap, mount, unmount } = lifecycles
