import { POI } from "."

const Card = ({ entity, mobile }: { entity: POI, mobile: boolean }) => {
    return <div className="mb-4 border-2 rounded-lg poi-card md:mb-0 border-bje-light swiper-slide" key={entity.headline}>
        <h5 className="mb-4 text-base md:text-2xl">{entity.headline}</h5>
        {!mobile ?
            <dl className="grid gap-2 md:grid-cols-4">
                {entity.type ?
                    <>
                        <dt>Info:</dt>
                        <dd className="col-span-3">{entity.type}</dd>
                    </>
                    : <></>}
                <dt>Entfernung:</dt>
                <dd className="col-span-3">{entity.distance}</dd>
                <dt>Adresse:</dt>
                <dd className="col-span-3">{entity.address}</dd>
                <dt>Tel-Nr:</dt>
                <dd className="col-span-3">{entity.phone}</dd>
                {entity.mail ? <>
                    <dt>E-Mail:</dt>
                    <dd className="col-span-3">{entity.mail}</dd>
                </> : <></>
                }
            </dl>

            :
            <div>
                {entity.type ?
                    <>
                        <b>Info:&nbsp;</b>
                        <span>{entity.type}</span>
                    </>
                    : <></>}
                <details className="p-2 mt-3 bj-detail border-y-2 border-bje-blue">
                    <summary className="text-bje-blue">
                        Mehr Informationen
                    </summary>
                    <dl className="mt-4">
                        <dt>Entfernung:</dt>
                        <dd className="col-span-3">{entity.distance}</dd>
                        <dt>Adresse:</dt>
                        <dd className="col-span-3">{entity.address}</dd>
                        <dt>Tel-Nr:</dt>
                        <dd className="col-span-3">{entity.phone}</dd>
                        {entity.mail ? <>
                            <dt>E-Mail:</dt>
                            <dd className="col-span-3">{entity.mail}</dd>
                        </> : <></>
                        }
                    </dl>
                </details>
            </div>
        }
    </div>

}

export default Card
