import { useTranslation } from 'react-i18next'
import * as Icons from '../../../assets/icons'
import SVG from 'react-inlinesvg'

export default () => {

    const { t } = useTranslation(["footer"])

    return <footer className="sticky top-[100vh]">
        <div className="bg-bje-primary relative">
            <div className="py-10 md:py-14 md:h-fit h-[224px]">
                <p className="font-overline text-white text-center mb-10 font-[BJEAvertaLight]">
                    {t('follow')}
                </p>
                <div className="bg-bje-primary-dark absolute w-full bottom-0">
                    <ul className="flex justify-center flex-wrap gap-4 md:justify-between w-1/2 mx-auto relative top-[-24px]">
                        <li className="bg-white rounded-full">
                            <a href="https://de-de.facebook.com/BUSCH.JAEGER.ELEKTRO/" target="_blank">
                                <SVG src={Icons.Facebook} />
                            </a>
                        </li>
                        <li className="bg-white rounded-full">
                            <a href="https://de.linkedin.com/company/busch-jaeger-elektro-gmbh" target="_blank">
                                <SVG src={Icons.Linkedin} />
                            </a>
                        </li>
                        <li className="bg-white rounded-full">
                            <a href="https://www.instagram.com/buschjaeger/" target="_blank">
                                <SVG src={Icons.Instagram} />
                            </a>

                        </li>
                        <li className="bg-white rounded-full">
                            <a href="https://www.youtube.com/c/Busch-Jaeger" target="_blank">
                                <SVG src={Icons.YouTube} />
                            </a>
                        </li>
                        <li className="bg-white rounded-full">
                            <a href="https://www.pinterest.com/buschjaeger" target="_blank">
                                <SVG src={Icons.Pinterest} />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="bg-bje-primary-darker md:py-6 lg:py-7 py-14">
            <div className="flex flex-col justify-between w-4/5 mx-auto md:flex-row">
                <div className="mb-10 text-white md:mb-0 lg:pb-0">
                    <p className="font-[BJEAverta]">© Busch-Jaeger Elektro GmbH 2023</p>
                </div>
                <div className="flex text-white">
                    <ul className="flex items-center md:w-1/3 w-full md:flex-row flex-col gap-x-6 list-none">
                        <li className="md:p-0 p-4">
                            <a className="underline font-bold font-[BJEAverta]" href="https://www.busch-jaeger.de/impressum">
                                {t('href.imprint')}
                            </a>
                        </li>
                        <li className="md:p-0 p-4">
                            <a className="underline font-bold font-[BJEAverta]" href="https://www.busch-jaeger.de/kontakt">
                                {t('href.contact')}
                            </a>
                        </li>
                        <li className="md:p-0 p-4">
                            <a className="underline font-bold font-[BJEAverta]" href="https://my.busch-jaeger.de/de/page/privacy-policy">
                                {t('href.tos')}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
}
