import { Suspense, useMemo } from 'react'
import { QueryClientProvider, QueryClient } from 'react-query'

import App from './app/index'
import LoadingIndicator from './app/components/LoadingIndicator'

const queryClient = new QueryClient()

export default function Root() {

    const loading = useMemo(() => <LoadingIndicator />, [])

    return (
        <Suspense fallback={loading}>
            <QueryClientProvider client={queryClient}>
                <App />
            </QueryClientProvider>
        </Suspense>
    )
}
