import { accessTokenAtom } from '../../customHooks/auth'
import { Link } from '@tanstack/react-location'
import { useEffect, useRef, useState } from "react"
import userIcon from '../../../assets/img/re-design/userIcon.svg'
import { useAtom } from 'jotai'
import MyBjePanel from './MyBJEPanel'
import { useTranslation } from 'react-i18next'

type navItem = {
    to: string,
    label: string
}

type props = {
    items: Array<navItem>
}

import '../../styles/burger-menu.css'

const BurgerMenu = ({ items }: props) => {

    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [accessToken] = useAtom(accessTokenAtom)
    const sidebarRef = useRef(null);
    const { t } = useTranslation(['home'])

    const toggleActiveMobile = () => {
        let menuBtn = document.querySelector('.menu-btn');
        let menu = document.querySelector('.nav');
        (menuBtn as HTMLElement).classList.toggle('active');
        (menu as HTMLElement).classList.toggle('active');
        setSidebarOpen(!isSidebarOpen);
    }

    return (
        <div>
            <div className="nav main-navigation hidden" id="mainNavigation">
                <nav className="flex gap-x-6 font-bold font-[BJEAvertaBold]" itemScope itemType="http://schema.org/SiteNavigationElement">
                    {items.map(item =>
                        <Link to={item.to} key={item.to} className="cursor-pointer p-4">
                            {({ isActive }) => (
                                <span className={isActive ? 'text-primary' : undefined}>
                                    {t(item.label)}
                                </span>
                            )}
                        </Link>
                    )}
                </nav>
            </div>
            <div className="menu-btn" onClick={toggleActiveMobile}>
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>
            <div ref={sidebarRef} style={{
                top: (() => document.querySelector('header')?.getBoundingClientRect().height + "px")()
            }} className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                <nav className="sidebar-nav">
                    <MyBjePanel
                        icon={userIcon}
                        client_id={process.env.SSO_CLIENT_ID as string}
                        token={accessToken}
                        provider={process.env.SSO_PROVIDER as string}
                    />
                    <ul>
                        {items.map(item =>
                            <li key={item.to}>
                                <Link to={item.to} className="cursor-pointer p-4" onClick={() => setSidebarOpen(false)}>
                                    {({ isActive }) => (
                                        <span className={isActive ? 'text-primary' : undefined}>
                                            {t(item.label)}
                                        </span>
                                    )}
                                </Link>
                            </li>
                        )}
                    </ul>
                </nav>
            </div>
        </div>
    )

}

export default BurgerMenu;
