import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Calendar from '../../components/Calendar'
import SelectedAppointment from '../../components/SelectAppointment'
import AppointmentForm from '../../components/AppointmentForm'
import ErrorBoundary from '../../components/ErrorBoundary'
import info from '../../../assets/icons/info.svg'
import { SelectedAppointmentType } from '../../types'

export default function Appointment() {
    const now = new Date()
    const [selectedDay, setSelectedDay] = useState<Date>(now)
    const [selectedAppointments, setSelectedAppointments] = useState<
        SelectedAppointmentType[]
    >([])

    useEffect(() => {
        setSelectedAppointments([])
    }, [selectedDay])

    const { t } = useTranslation(['booking'])

    return (
        <ErrorBoundary>
            <div className="grid grid-cols-1 md:p-12 gap-4 xl:gap-x-32 xl:grid-cols-2">
                <div className="px-4 md:p-0 col-span-1 xl:col-span-2 md:mb-10 mb-4">
                    <h1 className="text-3xl md:text-5xl text-black">
                        {t('title')}
                    </h1>
                </div>
                <div className="bg-sky-50 relative">
                    <span className="absolute right-8 top-0 mt-[-2px] g-primary bg-primary h-1 w-[34px]">&nbsp;</span>
                    <div className='p-4'>
                        <div className='flex justify-end'>
                            <img className="w-[32px]" src={info} alt={`icon_info`} />
                        </div>
                        <h2 className="card-title">
                            {t('specialist on site title')}
                        </h2>
                        <p>
                            <strong>{t('specialist on site text1')}</strong> {t('specialist on site text2')}
                        </p> 
                    </div>
                    
                    <Calendar
                        setSelectedDay={setSelectedDay}
                        selectedDay={selectedDay}
                        type="booking"
                    />
                    <SelectedAppointment
                        selectedDay={selectedDay}
                        selectedAppointments={selectedAppointments}
                        setSelectedAppointments={setSelectedAppointments}
                    />
                </div>
                <div>
                    <AppointmentForm
                        selectedAppointments={selectedAppointments}
                        selectedDay={selectedDay}
                    />
                </div>
            </div>
        </ErrorBoundary>
    )
}
