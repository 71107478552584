import { Dispatch, SetStateAction } from 'react'
import { UseMutationResult } from 'react-query'
import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { format, differenceInHours } from 'date-fns' // eslint-disable-line import/no-duplicates
import deLocale from 'date-fns/locale/de' // eslint-disable-line import/no-duplicates
import { inviteDialogAtom, acceptDialogAtom } from '../Jotai'
import { useAtom, useSetAtom } from 'jotai'
import clsx from 'clsx'
import SVG from 'react-inlinesvg'

import {
    getIcal,
    getQr,
    getApplePasskit,
    getGooglePasskit
} from '../../utils/api'
import ToggleSwitch from '../../components/ToggleSwitch'
import downloadIcon from '../../../assets/img/downloadIcon.svg'
import shareIcon from '../../../assets/img/shareIcon.svg'
import qrIcon from '../../../assets/img/ABB_picto_48x48_08_qr_0370.svg'
import appleWalletIcon from '../../../assets/img/DE_Add_to_Apple_Wallet_RGB_101421.svg'
import googleWalletIcon from '../../../assets/img/de_add_to_google_wallet_add-wallet-badge.svg'
import { Ticket } from '../../types'
import LoadingIndicator from '../LoadingIndicator'
import { accessTokenAtom } from '../../customHooks/auth'
import { parsedDate } from './functions'

type Props = {
    data: Ticket[] | undefined
    type: string
    showPast: boolean
    mutation: UseMutationResult<AxiosResponse<any, any>, unknown, number, unknown>
    actions: {
        page: Dispatch<SetStateAction<number>>
        setShowPast: Dispatch<SetStateAction<boolean>>
    }
}

const BlockView = ({ data, type, actions, showPast, mutation }: Props) => {

    const setIsOpenInvite = useSetAtom(inviteDialogAtom)
    const setIsOpenAccept = useSetAtom(acceptDialogAtom)
    const { t } = useTranslation(['ownAppointment'])
    const now = new Date()
    const startOfToday = new Date()
    startOfToday.setHours(0, 0, 0)
    const endOfToday = new Date()
    endOfToday.setHours(23, 59, 59)

    const [accessToken] = useAtom(accessTokenAtom)

    const handleShare = (appointment: Ticket) => {
        setIsOpenInvite([appointment])
    }
    const downloadCal = (appointment: Ticket) => {
        if (appointment.id) {
            getIcal(accessToken, appointment.id)
        }
    }

    const downloadQr = (appointment: Ticket) => {
        if (appointment.id) {
            getQr(accessToken, appointment.id)
        }
    }

    const downloadApplePasskit = (appointment: Ticket) => {
        if (appointment.id) {
            getApplePasskit(accessToken, new Date(appointment?.begin))
        }
    }

    const downloadGooglePasskit = (appointment: Ticket) => {
        if (appointment.id) {
            getGooglePasskit(accessToken, new Date(appointment?.begin))
        }
    }

    const handleMutate = (appointment: Ticket) => {
        if (appointment?.id) {
            mutation.mutate(appointment.id)
        }
    }

    const handleCancel = (
        appointment: Ticket,
        difference: number,
        beginDate: Date,
        endDate: Date
    ) => {
        if (appointment?.id && difference > 24) {
            setIsOpenAccept({
                accept: () => { handleMutate(appointment) },
                title: 'invite cancel title',
                titleProps: { date: format(beginDate, 'dd.LL.uuuu') },
                subtitle: 'invite cancel subtitle',
                subtitleProps: {
                    date: format(beginDate, 'dd.LL.uuuu'),
                    begin: format(beginDate, 'HH:mm', {
                        locale: deLocale,
                    }),
                    end: format(endDate, 'HH:mm', { locale: deLocale }),
                },
                buttonLabel: 'invite cancel button',
            })
        }
    }

    return (
        !data?.length ?
            <h3 className="alert text-2xl text-bje-primary">Sie haben keine Buchungen!</h3>
            : <div>
                <p className="grid grid-cols-2 px-4">
                    <h3 className="text-base">{t('meeting')}</h3>
                    <h3 className="text-base">{t('time')}</h3>
                </p>

                {data.map((appointment: Ticket) => {
                    try {
                        const beginDate = parsedDate(appointment?.begin)
                        const endDate = parsedDate(appointment?.end)
                        const difference = differenceInHours(beginDate, now)
                        const differenceToStartOfDay = differenceInHours(
                            beginDate,
                            startOfToday,
                        )
                        const differenceToEndOfDay = differenceInHours(
                            beginDate,
                            endOfToday,
                        )

                        return (
                            <details className="border-b-2" key={appointment?.id}>
                                <summary className="p-4 list-none grid grid-cols-2 font-[BJEAvertaBold] text-bje-primary p-2">
                                    <span>{format(beginDate, 'dd.LL.uuuu')}</span>
                                    {' '}
                                    <span>
                                        {format(beginDate, 'HH:mm', { locale: deLocale })}-
                                        {format(endDate, 'HH:mm', { locale: deLocale })}{' '}
                                        {t('clock')}
                                    </span>
                                </summary>
                                <div className="p-6">
                                    <p className="mb-6 border-b-2">{appointment?.comment}</p>
                                    {type === 'own' && (
                                        <div className="grid gap-4">
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn w-full gap-4 justify-start bg-white hover:text-white text-black normal-case"
                                                    onClick={() => handleShare(appointment)}
                                                >
                                                    <img src={shareIcon} alt={t('tooltip share')} />
                                                    {' '}
                                                    {t('tooltip share')}
                                                </button>
                                            </div>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn w-full gap-4 justify-start bg-white hover:text-white text-black normal-case"
                                                    onClick={() => downloadCal(appointment)}
                                                >
                                                    <img src={downloadIcon} alt={t('add_to_calendar')} />
                                                    {' '}
                                                    {t('add_to_calendar')}
                                                </button>
                                            </div>
                                            {differenceToStartOfDay >= 0 &&
                                                differenceToEndOfDay < 24 * 5 && (
                                                    <div className="grid grid-cols-2">
                                                        <div className="opacity-100 tooltip" data-tip={t("tooltip qr download")}>
                                                            <button
                                                                type="button"
                                                                onClick={() => downloadQr(appointment)}
                                                            >
                                                                <span>{t('download')}</span>
                                                                {' '}
                                                                <SVG className="w-32" src={qrIcon} />
                                                            </button>
                                                        </div>
                                                        <div className="alert grid justify-center space-between">

                                                            <div className="tooltip" data-tip={t("tooltip passkit download")}>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => downloadApplePasskit(appointment)}
                                                                >
                                                                    <SVG src={appleWalletIcon} />
                                                                </button>
                                                            </div>
                                                            <div className="tooltip" data-tip={t("tooltip passkit download")}>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => downloadGooglePasskit(appointment)}
                                                                >
                                                                    <SVG src={googleWalletIcon} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            <button
                                                type="button"
                                                disabled={difference < 24}
                                                className={clsx(
                                                    'btn h-12 px-4 text-sm normal-case border-2 border-bje-primary bg-white text-bje-primary'
                                                )}
                                                onClick={() => handleCancel(appointment, difference, beginDate, endDate)}
                                            >{t('cancel')}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </details>
                        )
                    } catch (e) {
                        return null
                    }
                })}
                <ToggleSwitch
                    checked={showPast}
                    containerClass="my-6"
                    onChange={(e) => {
                        actions.page(1)
                        actions.setShowPast(e.target.checked)
                    }} label={t('showPast')} />
            </div>
    )
}

export default BlockView
