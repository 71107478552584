import { Transition } from '@headlessui/react'
import { useTranslation, Trans } from 'react-i18next'
import { useAtom } from 'jotai'
import { consentApprovalDialogAtom } from '../Jotai'
import ErrorBoundary from '../ErrorBoundary'
import close from '../../../assets/img/close.svg'

export default function AcceptDialog() {
    const { t } = useTranslation(['consentApproval'])
    const [isOpen, setIsOpen] = useAtom(consentApprovalDialogAtom)

    const closeModal = () => {
        setIsOpen(undefined)
    }

    return (
        <ErrorBoundary>
            <Transition
                show={!!isOpen}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div
                    className="fixed inset-0 bg-black opacity-50"
                    style={{ zIndex: 1000 }}
                />
            </Transition>
            <Transition
                show={!!isOpen}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div
                    className="fixed flex flex-col w-5/6 p-6 overflow-hidden text-left align-middle transition-all transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-xl h-5/6 top-1/2 left-1/2"
                    style={{ zIndex: 1001, maxWidth: '1920px' }}
                >
                    <div className="flex gap-2 pb-16 text-2xl md:text-3xl text-bje-primary">
                        <span className="flex-1">{t('approval.title')}</span>
                        <img
                            src={close}
                            onClick={closeModal}
                            className="w-4 cursor-pointer"
                        />
                    </div>
                    <div className="overflow-y-auto overscroll-contain">
                        <Trans i18nKey="approval.text.1" ns="consentApproval" />
                        <Trans i18nKey="approval.text.2" ns="consentApproval" />
                        <Trans i18nKey="approval.text.3" ns="consentApproval" />
                        <Trans
                            i18nKey="approval.text.4"
                            ns="consentApproval"
                            components={[
                                <a
                                    className="text-primary"
                                    href="https://www.busch-jaeger-excellence.de/smarter-gallery"
                                    target="_blank"
                                >
                                    univers
                                </a>,
                            ]}
                        />
                        <Trans i18nKey="approval.text.5" ns="consentApproval" />
                        <Trans i18nKey="approval.text.6" ns="consentApproval" />
                        <Trans i18nKey="approval.text.7" ns="consentApproval" />
                        <Trans i18nKey="approval.text.8" ns="consentApproval" />
                        <Trans i18nKey="approval.text.9" ns="consentApproval" />
                        <Trans i18nKey="approval.text.10" ns="consentApproval" />
                    </div>
                </div>
            </Transition>
        </ErrorBoundary>
    )
}
