import { useEffect } from 'react'
import { useNavigate } from '@tanstack/react-location'

export default function useHandleError(isError: boolean, error: Error | null) {
    const navigate = useNavigate()

    useEffect(() => {
        if (isError) {
            if (error instanceof Error) {
                if (error.message === '401') {
                    navigate({ to: '/authorization-required' })
                    return
                } else if (error.message === '403') {
                    navigate({ to: '/' })
                }
            }

            console.error(error)
        }
    }, [isError, error])

    return null
}
