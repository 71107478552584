import {ErrorBoundary} from 'react-error-boundary'

//@ts-ignore
export const ErrorFallback = ({error}) => {
  return (
    <div className="p-4 text-sm shadow-xl w-96 bg-base-100" role="alert">
      <div className="p-8 text-base">
        <h2 className="mb-1 text-error">Something went wrong</h2>
        <p>{error.message}</p>
      </div>
    </div>
  )
}

//@ts-ignore
export default function Error({children}) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  )
}
