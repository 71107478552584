import { useTranslation } from 'react-i18next'
import deLocale from 'date-fns/locale/de' // eslint-disable-line import/no-duplicates
import { format } from 'date-fns' // eslint-disable-line import/no-duplicates
import { z } from 'zod'
import { Disclosure } from '@headlessui/react'

import SVG from 'react-inlinesvg'
import ErrorBoundary from '../ErrorBoundary'

import { AccessCodeRespone, Ticket } from '../../types'
import { getApplePasskit, getGooglePasskit } from '../../utils/api'
import appleWalletIcon from '../../../assets/img/DE_Add_to_Apple_Wallet_RGB_101421.svg'
import googleWalletIcon from '../../../assets/img/de_add_to_google_wallet_add-wallet-badge.svg'
import { useAtom, useSetAtom } from 'jotai'
import { accessTokenAtom } from '../../customHooks/auth'
import LoadingIndicator from '../LoadingIndicator'
import { ExportDialogAtom } from '../../components/Jotai/index'
import downloadIcon from '../../../assets/icons/download.svg'

type Props = {
    selectedDay: Date
    tickets: Ticket[]
    isLoading: boolean
    accessCodeImage: AccessCodeRespone | undefined
}

function BookingOverview({
    tickets,
    selectedDay,
    isLoading,
    accessCodeImage,
}: Props) {
    const { t } = useTranslation(['ownAppointment'])
    const [accessToken] = useAtom(accessTokenAtom)
    const setOpenExport = useSetAtom(ExportDialogAtom)

    const downloadApplePasskit = () => {
        if (selectedDay) {
            getApplePasskit(accessToken, selectedDay)
        }
    }

    const downloadGooglePasskit = () => {
        if (selectedDay) {
            getGooglePasskit(accessToken, selectedDay)
        }
    }

    if (isLoading) {
        return <LoadingIndicator />
    }

    return (
        <ErrorBoundary>
            <div className="grid grid-cols-1">
                <div className="grid pb-4 border-b-2 border-bje-light">
                    <button
                        className="h-12 px-4 mb-2 text-sm btn btn-primary"
                        type="button"
                        onClick={() => setOpenExport(true)}
                    >
                        <div className="flex items-center gap-4">
                            <div>
                                <SVG src={downloadIcon} width={24} />
                            </div>
                            <div>{t('open export')}</div>
                        </div>
                    </button>
                    <h3 className="text-2xl">
                        {t('appointments at', {
                            date: selectedDay
                                ? format(selectedDay, 'dd. MMMM uuuu', { locale: deLocale })
                                : '',
                        })}
                    </h3>
                </div>

                {tickets.map((appointment: Ticket) => {
                    try {
                        const beginDate = z.date().parse(new Date(appointment.begin))
                        const endDate = z.date().parse(new Date(appointment.end))

                        return (
                            <Disclosure key={appointment.id}>
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex justify-between w-full py-4 font-medium border-b-2 md:text-xl border-bje-light">
                                            <div className="md:grid md:grid-cols-2 space-x-4 md:items-center md:w-full font-[BJEAvertaBold] text-bje-primary">
                                                <span className="text-left">
                                                    <span className="text-2xl mr-6 text-left font-[BJEAvertaLight]">
                                                        {open ? '-' : '+'}
                                                    </span>
                                                    {format(beginDate, 'HH:mm')}-
                                                    {format(endDate, 'HH:mm', { locale: deLocale })}{' '}
                                                    {t('clock')}
                                                </span>
                                                <span className="text-left">
                                                    {appointment.user?.firstname}{' '}
                                                    {appointment.user?.lastname}
                                                </span>
                                            </div>
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="p-4 text-sm border-b-2 md:p-8 border-bje-light">
                                            <div className="gap-4 my-2 md:my-0 md:flex">
                                                <div className="font-bold averta-bold">
                                                    {t('contact person')}:
                                                </div>
                                                <div>
                                                    {appointment.user?.firstname}{' '}
                                                    {appointment.user?.lastname}
                                                </div>
                                            </div>
                                            <div className="gap-4 my-2 md:my-0 md:flex">
                                                <div className="font-bold averta-bold">
                                                    {t('contact email')}:
                                                </div>
                                                <div>
                                                    {appointment.user?.email}
                                                </div>
                                            </div>
                                            <div className="gap-4 my-2 md:my-0 md:flex">
                                                <div className="font-bold averta-bold">
                                                    {t('contact phone')}:
                                                </div>
                                                <div>
                                                    {appointment.user?.phone}
                                                </div>
                                            </div>
                                            <div className="gap-4 my-2 md:my-0 md:flex">
                                                <div className="font-bold averta-bold">
                                                    {t('comment')}:
                                                </div>
                                                <div>{appointment.comment}</div>
                                            </div>
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        )
                    } catch (e) {
                        return null
                    }
                })}
            </div>
            {accessCodeImage?.image && (
                <div className="p-2 border-2 rounded-lg text-md border-bje-light">
                    <h3 className="w-full text-2xl font-[BJEAvertaBold] ml-6">{t('access code label')}</h3>
                    <div className="grid items-center grid-cols-2 p-6">
                        {/* @ts-ignore */}
                        <a
                            className="w-2/4"
                            download={accessCodeImage.filename || `${t('access code')}.jpg`}
                            href={accessCodeImage.image}
                        >
                            <img
                                src={accessCodeImage.image}
                                alt={t('access code')}
                            />
                        </a>
                        <div className="grid gap-4">
                            <div
                                className="tooltip"
                                data-tip={t("tooltip passkit download")}
                            >
                                <button
                                    type="button"
                                    onClick={downloadApplePasskit}
                                >
                                    <img
                                        src={appleWalletIcon}
                                        alt={t('passkit download')}
                                        className="w-full"
                                    />
                                </button>
                            </div>
                            <div
                                className="tooltip"
                                data-tip={t("tooltip passkit download")}
                            >
                                <button
                                    type="button"
                                    onClick={downloadGooglePasskit}
                                >
                                    <img
                                        src={googleWalletIcon}
                                        alt={t('passkit download')}
                                        className="w-full"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </ErrorBoundary>
    )
}

export default BookingOverview
