import axios from 'axios'
import { formatISO } from 'date-fns'
import { Ticket } from '../../types'

const apiUrl: string = process.env.BACKEND as string

export const getAppointmentsCountForMonth = (
  accessToken: string | null,
  year: number,
  month: number,
) => (
  axios
    .get(`${apiUrl}/getAppointmentsCountForMonth/${year}/${month}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(response => response.data.data
    )
    .catch(err => {
      throw new Error(err.response.status)
    })
)

export const getTimesForDate = (accessToken: string | null, date: Date) => (
  axios
    .get(`${apiUrl}/getTimesForDate/${formatISO(date)}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(response => response.data.data)
    .catch(err => {
      throw new Error(err.response.status)
    })
)

export const createAppointment = (
  accessToken: string | null,
  appointmentData: Ticket,
) => {
  return axios
    .post(`${apiUrl}/tickets`, appointmentData, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
    .then(response => {
      return response.data.ticket
    })
    .catch(err => {
      throw new Error(err.response.status)
    })
}

export const deleteAppointment = (accessToken: string | null, id: number) => {
  return axios
    .delete(`${apiUrl}/tickets/${id}`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
    .catch(err => {
      throw new Error(err.response.status)
    })
}

export const getAppointments = (
  accessToken: string | null,
  page: number,
  pageSize: number,
  showPast: boolean,
) => {
  return axios
    .get(`${apiUrl}/tickets/${pageSize}/${page}?showPast=${showPast}`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
    .then(response => {
      return {
        tickets: response.data.tickets,
        pages: response.data.pages,
      }
    })
    .catch(err => {
      throw new Error(err.response.status)
    })
}

export const checkUsagePermission = (
  accessToken: string | null,
  lookup: string[]
) => {
  return axios
    .post(`${apiUrl}/usagePermissions`, {
      lookup: lookup,
    }, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      }
    })
    .then(response => response.data)
    .catch(err => {
      throw new Error(err.response.status)
    })
}

export const getAppointmentsForDate = (
  accessToken: string | null,
  date: Date,
  page: number,
  pageSize: number,
) => {
  return axios
    .get(`${apiUrl}/ticketsForDay/${formatISO(date)}/${pageSize}/${page}`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
    .then(response => {
      return {
        tickets: response.data.tickets,
        pages: response.data.pages,
      }
    })
    .catch(err => {
      throw new Error(err.response.status)
    })
}

export const getAccessCodeForDate = (accessToken: string | null, date: Date) =>
  axios
    .get(`${apiUrl}/accessCodeForDate/${formatISO(date)}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: 'arraybuffer',
    })
    .then(response => {
      if (response?.status === 200) {
        const filename = response.headers['content-disposition']
          .split('filename=')
          .pop()
        const image = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
        )
        const mimetype = 'image/jpeg'
        return {
          filename,
          image: `data:${mimetype};base64,${image}`,
        }
      }
      return {}
    })
    .catch(() => ({}))

export const getUser = (accessToken: string | null) => {
  return axios
    .get(`${process.env.BACKEND}/getUser`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
    .then(response => {
      return response?.data?.user
    })
    .catch(err => {
      return err.response
    })
}

export const handleInvites = (accessToken: string | null, data: unknown) => {
  return axios
    .post(`${apiUrl}/handleInvites`, data, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw new Error(err.response.status)
    })
}

export const getAccompanyingtext = (
  accessToken: string | null,
  ticketId: number,
) => {
  return axios
    .get(`${apiUrl}/accompanyingtext/${ticketId}`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
    .then(response => {
      return response.data.accompanyingtext
    })
    .catch(err => {
      return err.response
    })
}

export const getInvites = (accessToken: string | null, ticketId: number) => {
  return axios
    .get(`${apiUrl}/invites/${ticketId}`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
    .then(response => {
      return response.data.invites
    })
    .catch(err => {
      return err.response
    })
}

export const getQr = (accessToken: string | null, ticketId: number) => {
  return axios
    .get(`${apiUrl}/accessCodePdf/${ticketId}`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
      responseType: 'blob',
    })
    .then(response => {
      if (response.status === 200) {
        const filename =
          response.headers['content-disposition'].split('filename=').pop() ||
          'smarter-gallery.pdf'

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()

        return true
      }
    })
    .catch(err => err)
}

export const getApplePasskit = (accessToken: string | null, date: Date) => {
  return axios
    .get(`${apiUrl}/applewallet/${formatISO(date)}`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
      responseType: 'blob',
    })
    .then(response => {
      if (response.status === 200) {
        const filename =
          response.headers['content-disposition'].split('filename=').pop() ||
          'smarter-gallery.pdf'

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()

        return true
      }
    })
    .catch(err => err)
}

export const getGooglePasskit = (accessToken: string | null, date: Date) => {
  return axios
    .get(`${apiUrl}/googlewallet/${formatISO(date)}`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
    .then(response => {
      if (response.status === 200) {
        if (response.data?.link) {
          window.open(response.data.link, "_blank")
        }
        return true
      }
    })
    .catch(err => err)
}

export const getIcal = (accessToken: string | null, ticketId: number) => {
  return axios
    .get(`${apiUrl}/ical/${ticketId}`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
      responseType: 'blob',
    })
    .then(response => {
      if (response.status === 200) {
        const filename =
          response.headers['content-disposition'].split('filename=').pop() ||
          'smarter-gallery.ical'

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()

        return true
      }
    })
    .catch(err => err)
}

export const getExcel = (
  accessToken: string | null,
  startDate: string,
  endDate: string,
) =>
  axios
    .post(
      `${apiUrl}/excel`,
      { startDate, endDate },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'blob',
      },
    )
    .then(response => {
      if (response.status === 200) {
        const filename =
          response.headers['content-disposition'].split('filename=').pop() ||
          'smarter-gallery.xlsx'

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()

        return true
      }
      return false
    })
    .catch(err => err)

export const getConsentApproval = (accessToken: string | null) => {
  return axios
    .get(`${apiUrl}/getConsentApproval`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
    .then(response => {
      return response.data.consentApproval
    })
    .catch(err => {
      return err.response
    })
}

export const updateResponsibility = (
  accessToken: string | null,
  data: unknown,
) =>
  axios
    .post(`${apiUrl}/updateResponsibility`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(response => response.data.result)
    .catch(err => {
      throw new Error(err.response.status)
    })

export const getRightmanagementExcel = (accessToken: string | null, loaderHander: Function) =>
  axios
    .get(`${apiUrl}/rightmanagementexcel`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: 'blob',
    })
    .then(response => {
      if (response.status === 200) {
        loaderHander(false)
        const filename =
          response.headers['content-disposition'].split('filename=').pop() ||
          'smarter-gallery_rightmanagement.xlsx'

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()

        return true
      }
      return false
    })
    .catch(err => err)
