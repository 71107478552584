import { useToaster } from 'react-hot-toast'

const Notifications = () => {
    const { toasts, handlers } = useToaster()
    const { startPause, endPause } = handlers

    const renderIcon = (type: string) => {
        switch (type) {
            case 'success':
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="flex-shrink-0 w-6 h-6 stroke-current"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                )
            case 'error':
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="flex-shrink-0 w-6 h-6 stroke-current"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                    </svg>
                )
            default:
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        className="flex-shrink-0 w-6 h-6 stroke-current"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                    </svg>
                )
        }
    }

    return (
        <div
            onMouseEnter={startPause}
            onMouseLeave={endPause}
            className="fixed top-2 right-2 z-[9999]"
        >
            {toasts
                .filter(toast => toast.visible)
                .map(toast => {
                    let typeClass = ''
                    switch (toast.type) {
                        case 'success':
                            typeClass = 'alert-success'
                            break
                        case 'error':
                            typeClass = 'alert-error'
                            break
                        default:
                            typeClass = 'bg-primary text-white'
                            break
                    }

                    return (
                        <div
                            key={toast.id}
                            {...toast.ariaProps}
                            className={`mb-4 shadow-lg alert ${typeClass}`}
                        >
                            <div>
                                {renderIcon(toast.type)}
                                <span>{toast.message}</span>
                            </div>
                        </div>
                    )
                })}
        </div>
    )
}

export default Notifications
