import { useTranslation } from 'react-i18next'
import AppointmentCreateResult from '../../components/AppointmentCreateResult'
import ErrorBoundary from '../../components/ErrorBoundary'

import background from '../../../assets/img/re-design/smarter-gallery-header-full.webp'

export default function BookingResult() {
    const { t } = useTranslation(['booking'])

    return (
        <ErrorBoundary>
            <div className="grid grid-cols-1 gap-4 xl:m-12 xl:grid-cols-2">
                <div>
                    <h1 className="text-3xl text-center md:text-left py-8">
                        {t('booking title 2')}
                    </h1>
                    <img src={background} className="md:h-[400px]" alt="background" />
                </div>
                <AppointmentCreateResult />
            </div>
        </ErrorBoundary>
    )
}
