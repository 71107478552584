import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'react-query'
import { formatISO } from 'date-fns'

import Calendar from '../../components/Calendar'
import BookingOveriew from '../../components/BookingOverview'
import ErrorBoundary, { ErrorFallback } from '../../components/ErrorBoundary'
import useHandleError from '../../components/useHandleError'
import ExportDialog from '../../components/ExportDialog'

import { AccessCodeRespone, AppointmentRespone } from '../../types'
import { getAccessCodeForDate, getAppointmentsForDate } from '../../utils/api'
import { useAtom } from 'jotai'
import { accessTokenAtom } from '../../customHooks/auth'
import toast from 'react-hot-toast'

import { RBACRequest } from '@bjep-extmod/permission-request'

export default function Appointment() {
    const now = new Date()
    const [selectedDay, setSelectedDay] = useState<Date>(now)
    const { t } = useTranslation(['booking'])
    const [accessToken] = useAtom(accessTokenAtom)

    const page = 1
    const pageSize = 24

    const [codeUploadAllowed, setCodeUploadAllowed] = useState<boolean>(false)

    const rbac = new RBACRequest(process.env.SSO_PROVIDER as string)
    useEffect(() => {
        rbac.can(
            accessToken,
            "smarter-gallery",
            "upload-codes",
            false,
            "mail",
            "smarter_gallery"
        ).then(res => {
            if (res) {
                setCodeUploadAllowed(res.getValue())
            }
        }).catch(err => {
            console.error(err)
        })
    }, [accessToken])

    const {
        isLoading: isLoading1,
        isError: isError1,
        data,
        error: error1,
    } = useQuery<AppointmentRespone, Error>(
        [
            `appointmentsForDate`,
            { accessToken, pageSize, page, date: formatISO(selectedDay) },
        ],
        () => getAppointmentsForDate(accessToken, selectedDay, page, pageSize),
        { retry: 1 },
    )

    const {
        isLoading: isLoading2,
        isError: isError2,
        data: accessCodeImage,
        error: error2,
    } = useQuery<AccessCodeRespone, Error>(
        [`accessCodeForDate`, { accessToken, date: formatISO(selectedDay) }],
        () => getAccessCodeForDate(accessToken, selectedDay),
        { retry: 1 },
    )

    const isLoading = isLoading1 || isLoading2
    const isError = isError1 || isError2
    const error = error1 || error2

    useHandleError(isError, error)

    if (isError) {
        if (error instanceof Error) {
            return <ErrorFallback error={error} />
        }
        return <ErrorFallback error={{ message: 'Error' }} />
    }

    const fileUploadRef = useRef(null)
    const codeDateMonth = useRef(null)
    const codeDateYear = useRef(null)
    const codeDateDay = useRef(null)

    const currentDate = new Date()

    return (
        <ErrorBoundary>
            {codeUploadAllowed ?
                <form onSubmit={(e) => {
                    e.preventDefault()
                    const input = fileUploadRef.current as unknown as HTMLInputElement
                    const codeDateMonthEle = codeDateMonth.current as unknown as HTMLInputElement
                    const codeDateYearEle = codeDateYear.current as unknown as HTMLInputElement
                    const codeDateDayEle = codeDateDay.current as unknown as HTMLInputElement

                    if (input.files) {
                        const formData = new FormData()

                        for (let c = 0; c < input.files.length; c++) {
                            formData.append('files', input.files[c])
                        }

                        if (codeDateMonth.current) {
                            formData.append("code-date-month", codeDateMonthEle.value)
                        }

                        if (codeDateYear.current) {
                            formData.append("code-date-year", codeDateYearEle.value)
                        }
                        if (codeDateDay.current) {
                            formData.append("code-date-since-day", codeDateDayEle.value)
                        }

                        fetch(`${process.env.BACKEND}/codeupload`, {
                            method: "POST",
                            body: formData,
                            headers: {
                                "Authorization": "Bearer " + accessToken,
                            }
                        }).then(e => {
                            switch (e.status) {
                                case 200:
                                    toast.success("Upload erfolgreich")
                                    break;
                                default:
                                    e.text().then(m => toast.error(m))
                            }
                        })
                    }

                    return

                }}
                    className="content w-1/3"
                >
                    <label htmlFor="code-upload-year">
                        Codes für Jahr:
                    </label>
                    <input type="number"
                        className="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-4"
                        id="code-upload-year"
                        defaultValue={currentDate.getFullYear()}
                        step={1}
                        min={currentDate.getFullYear()}
                        max={2142}
                        ref={codeDateYear}
                    />
                    <label
                        className="mt-4 text-sm text-gray-900 dark:text-white"
                        htmlFor="code-date-month" >
                        Codes für Monat:
                    </label>
                    <input type="number"
                        id="code-upload-month"
                        className="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-4"
                        defaultValue={currentDate.getMonth() + 1}
                        step={1}
                        min={1}
                        max={12}
                        ref={codeDateMonth}
                    />
                    <label
                        className="mt-4 text-sm text-gray-900 dark:text-white"
                        htmlFor="code-date-month" >
                        Codes ab Tag:
                    </label>
                    <input type="number"
                        id="code-upload-since-day"
                        className="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-4"
                        defaultValue={1}
                        step={1}
                        min={1}
                        max={31}
                        ref={codeDateDay}
                    />
                    <input
                        className="col-span-2 block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-4"
                        ref={fileUploadRef} type="file" accept="image/*" multiple={true} />
                    <button
                        className="col-span-2 btn alert alert-success text-white font-[BJEAvertaBold]"
                    >Upload</button>
                </form>
                : <></>}
            <div className="grid grid-cols-1 gap-4 content xl:gap-x-32 xl:grid-cols-2">
                <div className="col-span-1 xl:col-span-2">
                    <h1 className="text-3xl font-[BJEAvertaBold]">
                        {t('booking calendar title 2')}
                    </h1>
                </div>

                <div className="bg-sky-50 grid grid-cols-1 gap-2">
                    <Calendar
                        setSelectedDay={setSelectedDay}
                        selectedDay={selectedDay}
                        type="view"
                    />
                </div>
                <div className="self-center h-full">
                    <div className="flex flex-col h-full gap-4">
                        <BookingOveriew
                            tickets={data?.tickets || []}
                            selectedDay={selectedDay}
                            isLoading={isLoading}
                            accessCodeImage={accessCodeImage}
                        />
                    </div>
                </div>
            </div>
            <ExportDialog />
        </ErrorBoundary >
    )
}
