import { Fragment, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useAtom, useAtomValue } from 'jotai'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { format, formatISO } from 'date-fns' // eslint-disable-line import/no-duplicates
import { de } from 'date-fns/locale' // eslint-disable-line import/no-duplicates
import clsx from 'clsx'
import SVG from 'react-inlinesvg'
import arrow from '../../../assets/img/Arrow.svg'
import { ExportDialogAtom } from '../Jotai/index'
import ErrorBoundary from '../ErrorBoundary'
import close from '../../../assets/img/close.svg'
import { getExcel } from '../../utils/api'
import { accessTokenAtom } from '../../customHooks/auth'

const FormInput = z.object({
  startDate: z.string(),
  endDate: z.string(),
})

type FormValues = z.infer<typeof FormInput>

export default function ExportDialog() {
  const { t } = useTranslation(['export'])

  const [isOpen, setIsOpen] = useAtom(ExportDialogAtom)
  const [accessToken] = useAtom(accessTokenAtom)


  const today = new Date()
  const defaultValues: FormValues = {
    startDate: format(today, 'yyyy-MM-dd', {
      locale: de,
    }),
    endDate: format(new Date(today.getFullYear(), 11, 31), 'yyyy-MM-dd', {
      locale: de,
    }),
  }

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(FormInput),
    defaultValues,
    mode: 'onBlur',
  })

  const onSubmit = (data: FormValues) => {
    try {
      const start = formatISO(new Date(data.startDate))
      const end = formatISO(new Date(data.endDate))

      getExcel(accessToken, start, end)
    } catch (e) {
      console.error(e) // eslint-disable-line no-console
    }
  }

  const onInvalid = (error: any) => {
    console.error(error) // eslint-disable-line no-console
  }

  useEffect(() => {
    if (!isOpen) {
      reset(defaultValues)
    }
  }, [isOpen])

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <ErrorBoundary>
      <Transition
        show={!!isOpen}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed inset-0 bg-black opacity-50"
          style={{ zIndex: 1000 }}
        />
      </Transition>
      <Transition
        show={!!isOpen}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed w-1/3 max-h-screen p-6 overflow-auto text-left align-middle transition-all transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-xl top-1/2 left-1/2"
          style={{ zIndex: 1001, maxWidth: '1920px' }}
        >
          <div className="flex gap-2 pb-16 text-3xl text-bje-primary">
            <span className="flex-1">{t('export title')}</span>
            <button type="button" onClick={closeModal}>
              <img src={close} className="cursor-pointer" alt="close" />
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
            <div className="grid grid-cols-1 gap-2">
              <div>
                <span className="text-sm">{t('timeline')}</span>
              </div>
              <div className="flex items-end gap-2">
                <section className="grid grid-cols-2 gap-2 grow section">
                  <div className="w-full form-control">
                    <label htmlFor="startDate" className="label">
                      <span className="label-text">{t('start date')}</span>
                    </label>
                    <input
                      id="startDate"
                      type="date"
                      className={clsx(
                        'w-full rounded-none input h-12 px-4 text-sm',
                        errors?.startDate?.message
                          ? 'bg-error'
                          : 'bg-zinc-100',
                      )}
                      {...register('startDate')}
                    />
                    {errors?.startDate && (
                      <p className="pt-1 text-error">
                        {t(errors?.startDate?.message || '')}
                      </p>
                    )}
                  </div>
                  <div className="w-full form-control">
                    <label htmlFor="endDate" className="label">
                      <span className="label-text">{t('end date')}</span>
                    </label>
                    <input
                      id="endDate"
                      type="date"
                      className={clsx(
                        'w-full rounded-none input h-12 px-4 text-sm',
                        errors?.endDate?.message
                          ? 'bg-error'
                          : 'bg-zinc-100',
                      )}
                      {...register('endDate')}
                    />
                    {errors?.endDate && (
                      <p className="pt-1 text-error">
                        {t(errors?.endDate?.message || '')}
                      </p>
                    )}
                  </div>
                </section>
              </div>
              <div className="flex justify-end gap-2 pt-16">
                <button
                  className="h-12 px-4 text-sm rounded-none btn btn-primary"
                  type="submit"
                >
                  <div className="flex items-center gap-2">
                    <div>
                      <SVG src={arrow} width={24} />
                    </div>
                    <div>{t('export submit')}</div>
                  </div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </Transition>
    </ErrorBoundary>
  )
}
