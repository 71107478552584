import { useAtom, useSetAtom } from 'jotai'
import { format } from 'date-fns'
import { de } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { useNavigate } from '@tanstack/react-location'
import { Ticket } from '../../types'
import Invite from '../Invite'
import { inviteDialogAtom, lastInsertedAtom } from '../Jotai'
import { accessTokenAtom } from '../../customHooks/auth'
import ErrorBoundary from '../ErrorBoundary'
import downloadIcon from '../../../assets/img/downloadIcon.svg'
import { getIcal } from '../../utils/api/index'

export default function AppointForm() {
    const setIsOpen = useSetAtom(inviteDialogAtom)
    const [lastInserted, setLastInserted] = useAtom(lastInsertedAtom)
    const [accessToken] = useAtom(accessTokenAtom)
    const navigate = useNavigate()

    if (!lastInserted || lastInserted.length === 0) {
        navigate({ to: '/' })
        return null
    }

    const { t } = useTranslation(['booking'])
    const date = new Date(lastInserted[0].begin)

    const handleFinish = () => {
        setLastInserted(null)
        navigate({ to: '/' })
    }

    const handleOpen = () => {
        setIsOpen(lastInserted)
    }

    const downloadICal = () => {
        if (lastInserted[0].id) {
            getIcal(accessToken, lastInserted[0].id)
        }
    }

    return (
        <ErrorBoundary>
            <div className="flex items-center mx-4 flex-cols md:mx-0">
                <div className="grid grid-cols-1 gap-2 ">
                    <div className="mb-4">
                        <h2 className="text-2xl leading-5 text-bold">
                            {t('booking subtitle')}
                        </h2>
                        <h2 className="text-2xl text-bold">{t('booking subtitle2')}</h2>
                    </div>
                    <div className="grid">
                        <section className="border-2 rounded-lg border-bje-light px-8 py-4 mb-6 font-[BJEAverta] text-bold">
                            <div className="flex gap-4 text-sm">
                                <div className="font-bold font-[BJEAvertaBold]">{t('at the')}:</div>
                                <div>
                                    {format(date, 'EEEE, dd. MMMM yyyy', {
                                        locale: de,
                                    })}
                                </div>
                            </div>
                            <div className="flex gap-4 mt-2 text-sm">
                                <div className="font-[BJEAvertaBold]">{t('from')}:</div>
                                <div className="grid grid-cols-1 mb-2">
                                    {lastInserted.map((ticket: Ticket) => {
                                        try {
                                            const beginDate = z.date().parse(new Date(ticket?.begin))
                                            const endDate = z.date().parse(new Date(ticket?.end))

                                            return (
                                                <div key={`${ticket.begin}`} className="flex gap-2 mb-1">
                                                    {format(beginDate, 'HH:mm', {
                                                        locale: de,
                                                    })}{' '}
                                                    -{' '}
                                                    {format(endDate, 'HH:mm', {
                                                        locale: de,
                                                    })}
                                                </div>
                                            )
                                        } catch (e) {
                                            return null
                                        }
                                    })}
                                </div>
                            </div>
                            <div className="flex gap-2" onClick={downloadICal}>
                                <img className="leading-10 cursor-pointer" src={downloadIcon} />
                                <span className="text-sm leading-10 cursor-pointer">
                                    {t('donwload calendar')}
                                </span>
                            </div>
                        </section>
                        <div className="grid gap-4 mb-12 md:grid-cols-2 md:gap-8">
                            <button
                                className="h-12 px-8 text-sm rounded-lg btn btn-primary btn-outline normal-case !font-[BJEAvertaBold]"
                                onClick={handleOpen}
                            >
                                <div className="flex items-center gap-2">
                                    {t('share')}
                                </div>
                            </button>
                            <button
                                className="h-12 px-8 text-sm rounded-lg btn btn-primary normal-case !font-[BJEAvertaBold]"
                                onClick={handleFinish}
                            >
                                <div className="flex items-center gap-2">
                                    {t('finish')}
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Invite />
        </ErrorBoundary>
    )
}
