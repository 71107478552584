import './style.css'

const BJIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAABGCAYAAABxLuKEAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4goEBw0mMGZy6gAABNdJREFUeNrtmmtsFFUUx/9nZmcfbalAadkWW2xLERsaTajBBKMlBqNGUWxiq2KE0MRYlBD5xgejofKhGoJJFRNbQArUBoiJQRSjIdGQxg8Q0FjIUvqyFLot3e1jH7OPOX5gnZ0+KBHiTp3ek2wy98692ZPfnHteM8TMEDJVJIFAgBFgBBgBRoARYAQYAUaAEWAEGCECjABzj2KbDUpQ3cks5JashER2RELX+K2H203Xyax+DO1pS0duwRpItsOwO7Jh1IM1IDReC0n6hl/LvzEnwNCRPsLQX3bklZyFLK+acbGm+eD3ruWahy7OCYuhVu9VKErRhElmDUAcgAKSAHDSevo7HuR3V3ss7XzpmPeHCVCYAf/g24jFihEJL8foUAkioT6AEhskILf4FDWcz7Cs86WvrxdBcawxQPFjoPMRri3vmbQ0n1pvfAvF+QLAgCQVY2FOBYCT1rSY8FghiJJPPjS2bRoot5hVudcjpl5LIAVsyifWPUqS8qp+RLQ4eOPS5hnXx6J1+rWsFFrYx3CxYdB/x+UOl9cwslsXjM3epUebeDzvjuvV4GLDKGpdMOHAV/q14gA1d1XPuF5WPkweK7XTumDSMnsBHtfjtCvzM/riYtFtIthx2J3Z+kRgbIdlwXB1bhci6vmEAyZI0kLkFHjoYMdOHcjOliV0fMgHu7NSLxOYO6FFz8yBzHfgTyj20gmToUAZAv5uZOVdBVGOoSxQMeZby5uXtVm/7TDY+xji0V49dDMDNtkJggSisKGYHIHPW5ZqKKZW1wk/sgOyrQzMl3D2+Mf8aa1Gh7vXw5HxMoBhXPi5jj+qGjZFOWY29Yf9Hpo6d5nM1iulFkNNHgXpGZUg+X7gX/wxSTEM9p7greV9liwiIUsVsLta7mqvu7AegMOaztfurLmX3bTnF5c1wZBUj7s9urFoN7/3RMiyUYmOdK+C7FgK1qCH65nDA0BymKvdp+iEj7hyAVsSzP9FUv76hA51PYu0ec+AWU5YBEHjy2jY3sC/tjA1966DM+0lQ9QiaFoHqt17OYVPMbXh+mhfKZzpF6c8EGYgFlmNwMgVzM/5A8CSSTuB4OjnvLFgqzWdbzy2bVorJQKiahYkWQbYNa2fcWXUEhFZEwzz5PReBTCMWOwnnG78kTctG4LPuwtE4wD7J1tVKo9SasHItv1gThaJWuwKun/P51ey13HzrjgA8JYVe3nD/HmIqIdgNBA1uNu6/ZjX8zsQjyYrZcm2Eu7iDVNO1gFPART7G7r/1bQ+hAMWfksAAFXup27lMP/ks66mKb4jLXMfSFqQSAqBsZu7ecsKn6XBMDNj5GbSSiTJgdaB07q1NLa/CLvzOf1+JHiONy/fZ/k8BgAw6v0e9y3qAlFhwvdUUGP7A3Cm+7Fg8ZeGlqaKcLDGDBVN6eDx9sdVDPW9YwjXCjIXHYWmVYOkZAM8Hv2O3yy+YEoTzdQOXkv/AThcm26T8wCXfkvj958PzRmLMVjEB9C04LT3woGnzYJiOhjeuLQHUbVpUpXN0OI9uNx2xkzdZkV1TccGo5BtyUAwfL2ca0rPmanT7PhqUw2uMxSL9WZDmVX9GDroeRJMuQj5W7n2URZgZqmID6AFGAFGgBFgBBgBRoARYAQYAUaAESLACDACzH8kfwOrWLGCmUVUbAAAAABJRU5ErkJggg=="

declare type Props = {
    text?: string
}

export default function(args: Props) {
    const hint = args.text ? <h1 className="bje-loading-indicator_text text-base md:text-2xl">{args.text}</h1> : <></>

    return (
        <div className="bje-loading-indicator_container">
            <div className="bje-loading-indicator_indicator"></div>
            <img src={BJIcon} className="bje-loading-indicator_icon" />
            {hint}
        </div>
    )
}
