import React, { useContext, useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Trans, useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'
import SVG from 'react-inlinesvg'

import { useAtom, useAtomValue } from 'jotai'
import debounce from 'lodash/debounce'

import {
    createTable,
    getCoreRowModel,
    useTableInstance,
    PaginationState,
    getFilteredRowModel,
    getPaginationRowModel,
    TableInstance,
    Row,
    SortingState,
    getSortedRowModel,
    // @ts-ignore
} from '@tanstack/react-table'

import { Responsibility } from '../../types'
import ErrorBoundary from '../../components/ErrorBoundary'
import { userAtom } from '../../components/Jotai'
import {
    updateResponsibility,
    getRightmanagementExcel,
    checkUsagePermission,
} from '../../utils/api'

import magnify from '../../../assets/icons/magnifyglass.svg'
import { accessTokenAtom } from '../../customHooks/auth'

import useWebSocket from 'react-use-websocket'
import LoadingIndicator from '../../components/LoadingIndicator'
import { Switch, Listbox } from '@headlessui/react'
import ReactPaginate from 'react-paginate'
import Spinner from '../../components/Spinner'

import { MobileContext } from '../..'
const table = createTable().setRowType<Responsibility>()

function RightManagement() {

    const [accessToken] = useAtom(accessTokenAtom)
    const [isLoading, setIsLoading] = useState(true)
    const [loadingMessage, setLoadingMessage] = useState("geladene Kontakte: ")
    const { t } = useTranslation(['rightManagement'])
    const user = useAtomValue(userAtom)

    const [globalFilter, setGlobalFilter] = React.useState('')
    const [useRightFilter, setUseRightFilter] = React.useState(false)
    const [data, setData] = React.useState<Responsibility[]>([])
    const [initData, setInitData] = React.useState<Responsibility[]>([])
    const [sorting, setSorting] = React.useState<SortingState>([])
    const [pagination, setPagination] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    })

    const [tempData, setTempData] = useState<Responsibility[]>([])
    const [hasUsageRight, setHasUsageRight] = useState<string[]>([])

    // websocket fetching
    // faster rebuild for now, other than grpc streaming needs more work

    const {
        lastMessage,
        readyState,
    } = useWebSocket(process.env.BACKEND_WS + "/getResponsibilities" as string, {
        queryParams: {
            token: accessToken
        }
    });


    const [loadedPermissions, setLoadedPermissions] = useState(false)


    // gets all email adresses shown on the current page to check for permission
    const listedPageMail = () => {
        return data.filter(e => !e.invalid).map(({ email }) => email)
    }

    const usagePermissions = useQuery(["rbac", { accessToken }],
        () => {
            const lookup = listedPageMail()
            return checkUsagePermission(accessToken, lookup)
        },
        {
            retry: 0, enabled: data.length > 1 //&& loadedPermissions === false
        }
    )

    useEffect(() => {
        if (usagePermissions.isSuccess) {
            if (usagePermissions.data && !loadedPermissions) {
                const permissions = Object.keys(usagePermissions.data)
                const tempData = data
                permissions.forEach((u) => {
                    const f = data.find(e => e.email === u)
                    if (f) {
                        setHasUsageRight((d) => [...d, f.email])
                        const d = data.filter(el => el.email === f.email)
                        d.forEach(e => {
                            e.useRight = true
                        })
                    }
                })
                setData(tempData)
            }

            setLoadedPermissions(true)
        }
    }, [usagePermissions])


    useEffect(() => {
        if (lastMessage && lastMessage.data) {
            const wsDATA = JSON.parse(lastMessage.data)
            const mergedData = tempData.concat(wsDATA)

            setTempData(mergedData)
        }

    }, [lastMessage])

    useEffect(() => {
        if (readyState == 3) {
            setLoadingMessage("Initialisiere Tabelle")

            setInitData(tempData)
            setData(tempData)
            setIsLoading(false)
        }
    }, [readyState])

    React.useEffect(() => {
        if (useRightFilter) {
            const filterData = data.filter(el => el.useRight)
            setData(filterData)
        } else {
            setData(initData)
        }
    }, [useRightFilter, initData])

    const isMobile = useContext(MobileContext)

    const columns = React.useMemo(() => {

        const cols = [
            table.createDataColumn('firstname', {
                header: t('firstname'),
            }),
            table.createDataColumn('lastname', {
                header: t('lastname'),
            }),
            table.createDataColumn('company', {
                header: t('company'),
            }),
            table.createDataColumn('city', {
                header: t('city'),
            }),
            table.createDataColumn('email', {
                header: t('email'),
            }),
            table.createDataColumn('useRight', {
                header: () => <div className="w-full text-center">{t('useRight')}</div>,
                cell: ({ getValue, row, column: { id }, instance }) => {
                    return <Check rightsLoaded={loadedPermissions} getValue={() => hasUsageRight.indexOf((row.original as Responsibility).email) !== -1} row={row} id={id} instance={instance} />
                },
            }),
        ]

        return cols
    }, [user, hasUsageRight, loadedPermissions])

    const instance = useTableInstance(table, {
        data,
        columns,
        state: {
            pagination,
            globalFilter,
            sorting,
        },
        meta: {
            updateData: (rowIndex: number, columnId: string, value: any) => {
                setData(old => {
                    if (columnId === "useRight") {

                        if (value) {
                            setHasUsageRight(origin =>
                                [...origin, (instance.getRow(rowIndex.toString()).original as Responsibility).email]
                            )
                        } else {
                            const search = (instance.getRow(rowIndex.toString()).original as Responsibility).email
                            setHasUsageRight(origin =>
                                origin.filter(e => e !== search)
                            )
                        }

                    }

                    return old.map((row, index) => {
                        if (index === rowIndex) {
                            return {
                                ...old[rowIndex]!,
                                [columnId]: value,
                            }
                        }
                        return row
                    })

                })
                setInitData(old =>
                    old.map((row, index) => {
                        if (index === rowIndex) {
                            return {
                                ...old[rowIndex]!,
                                [columnId]: value,
                            }
                        }
                        return row
                    }),
                )
            },
        },
        onPaginationChange: setPagination,
        // Pipeline
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
    })

    const debouncedHandleInput = debounce(e => {
        setGlobalFilter(e.target.value)
    }, 500)

    const paginationButtonCls = "btn btn-primary min-h-0 h-8 rounded-md"
    const [exportRunning, setExportRunning] = useState(false)

    return (
        <ErrorBoundary>
            {exportRunning &&
                <div id="loading-overlay" className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-60">

                    <svg className="animate-spin h-8 w-8 text-white mr-3" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path className="opacity-75" fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                        </path>
                    </svg>

                    <span className="text-white text-3xl font-bold">Loading...</span>

                </div>
            }
            <div className="grid grid-cols-1 gap-4 mx-2 md:mx-16 mb-32 mt-6">
                <div>
                    <h1 className="text-3xl md:text-5xl">
                        <span className="averta-bold">{t('title 2')}</span>
                    </h1>
                </div>
                <div className="flex items-center flex-col md:flex-row">
                    <button
                        className="md:w-1/4 w-full h-12 px-4 text-sm normal-case btn btn-primary md:mb-0 mb-4"
                        type="button"
                        onClick={() => {
                            setExportRunning(true)
                            // lets show some overlay
                            getRightmanagementExcel(accessToken, setExportRunning)
                        }}
                    >
                        {t('export')}
                    </button>
                    <label
                        htmlFor="filterUseRight"
                        className="w-3/4 justify-start gap-2 label mx-4"
                    >
                        <Switch
                            id="filterUseRight"
                            checked={useRightFilter}
                            onChange={(e) => setUseRightFilter(!useRightFilter)}
                            className={`${useRightFilter ? 'bg-bje-primary' : 'bg-teal-900'}
          relative inline-flex w-[46px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:bg-bje-primary`}
                        >
                            <span
                                aria-hidden="true"
                                className={`${useRightFilter ? 'translate-x-5' : 'translate-x-0'}
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                            />
                        </Switch>
                        <span className="text-sm cursor-pointer label-text">
                            {t('filter useRight')}
                        </span>
                    </label>
                    <div className="w-full h-full flex items-center justify-end p-4">
                        <input
                            type="text"
                            placeholder="Suchen"
                            className="h-full w-full md:w-1/2 p-4 bg-zinc-100 rounded-l-lg border-2"
                            onChange={debouncedHandleInput}
                        />
                        <span className="bg-bje-primary p-4 h-full rounded-r-lg">
                            <SVG src={magnify} />
                        </span>
                    </div>
                </div>
                <div id="rights-table">
                    {!isLoading ?
                        !isMobile ?
                            <table className="table w-full table-compact">
                                <thead>
                                    {instance.getHeaderGroups().map((headerGroup: any) => (
                                        <tr key={headerGroup.id}>
                                            {headerGroup.headers.map((header: any) => (
                                                <th className="pb-4" key={header.id} colSpan={header.colSpan}>
                                                    {header.isPlaceholder ? null : (
                                                        <div
                                                            className={
                                                                header.column.getCanSort()
                                                                    ? 'w-full select-none'
                                                                    : 'w-full cursor-default'
                                                            }
                                                            onClick={header.column.getToggleSortingHandler()}
                                                            onKeyDown={header.column.getToggleSortingHandler()}
                                                            role="button"
                                                            tabIndex={0}
                                                        >
                                                            {header.renderHeader()}
                                                            {{
                                                                asc: ' 🔼',
                                                                desc: ' 🔽',
                                                            }[header.column.getIsSorted() as string] ?? null}
                                                        </div>
                                                    )}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody>
                                    {instance.getRowModel().rows.map((row: any) => (
                                        <tr key={row.id}>
                                            {row.getVisibleCells().map((cell: any) => (
                                                <td className="py-2" key={cell.id}>{cell.renderCell()}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            : instance.getRowModel().rows.map((row: any) => {
                                const cells = row.getVisibleCells()
                                return (
                                    <details key={row.id} className="bj-detail p-2 border-b-2 border-bje-light" >
                                        <summary className="font-[BJEAvertaBold] text-bje-dark-blue">{cells[0].getValue()}&nbsp;{cells[1].getValue()}</summary>
                                        <dl>
                                            <dt>Firmenname:</dt>
                                            <dd>{cells[2].getValue()}</dd>
                                            <dt>Matchcode:</dt>
                                            <dd>{cells[3].getValue()}</dd>
                                            <dt>Stadt:</dt>
                                            <dd>{cells[4].getValue()}</dd>
                                            <dt>E-Mail:</dt>
                                            <dd>{cells[5].getValue()}</dd>
                                            <dt className="float-left">Nutzung Buchungstool:</dt>
                                            <dd>{cells[6].renderCell()}</dd>
                                        </dl>
                                    </details>
                                )
                            })
                        : <LoadingIndicator text={`${loadingMessage} ${tempData.length}`} />}
                </div>
                <div className="grid md:grid-cols-3 items-center md:gap-2 gap-4">
                    <div>
                        <Trans ns="rightManagement" i18nKey="page_shown" values={{ current: instance.getState().pagination.pageIndex + 1, total: instance.getPageCount() }} />
                    </div>
                    <ReactPaginate
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageLinkClassName={paginationButtonCls + " bg-white text-bje-primary hover:bg-bje-dark-grey hover:border-white hover:text-bje-light-grey"}
                        activeLinkClassName={paginationButtonCls + " !bg-bje-primary !text-white"}
                        previousLinkClassName={instance.getState().pagination.pageIndex > 0 ? paginationButtonCls + " bg-white text-bje-primary hover:text-white" : 'hidden'}
                        nextLinkClassName={instance.getState().pagination.pageIndex == instance.getPageCount() - 1 ? "hidden" : paginationButtonCls + " bg-white text-bje-primary hover:text-white"}
                        className="flex gap-4 p-2"
                        nextLabel=">"
                        previousLabel="<"
                        forcePage={instance.getState().pagination.pageIndex}
                        onPageChange={(e) => instance.setPageIndex(e.selected)}
                        pageCount={instance.getPageCount()} />
                    <div className="flex gap-6 justify-end">
                        <span className="flex items-center gap-1">
                            {t('go to page')}
                            <input
                                type="number"
                                defaultValue={instance.getState().pagination.pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    instance.setPageIndex(page)
                                }}
                                min={1}
                                max={instance.getPageCount()}
                                className="w-16 p-1 border rounded"
                            />
                        </span>
                        <div className="w-1/2 relative">
                            <Listbox
                                value={instance.getState().pagination.pageSize}
                                onChange={selection => {
                                    instance.setPageSize(Number(selection))
                                }}
                            >
                                <Listbox.Button
                                    className="relative w-4/6 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                                >{t('show') + ' ' + instance.getState().pagination.pageSize}</Listbox.Button>
                                <Listbox.Options className="absolute right-0 bottom-0 w-4/6 max-h-60 right-2/6 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {[10, 20, 30, 40, 50].map(pageSize => (
                                        <Listbox.Option
                                            className={({ active }) =>
                                                `relative cursor-default select-none py-2 px-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                                }`
                                            }
                                            key={pageSize}
                                            value={pageSize}>
                                            {t('show')} {pageSize}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Listbox>
                        </div>
                    </div>
                </div>
            </div>
        </ErrorBoundary >
    )
}

type CheckProps = {
    rightsLoaded: boolean
    getValue: Function
    row: Row<any>
    id: string
    instance: TableInstance<any>
}

function Check({ rightsLoaded, getValue, row, id, instance }: CheckProps) {
    const { t } = useTranslation(['rightManagement'])
    const [accessToken] = useAtom(accessTokenAtom)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.checked

        updateResponsibility(accessToken, {
            email: row.original.email,
            useRight: value,
        }).then((result: boolean) => {
            if (result) {
                toast.success(t('success update'))
            } else {
                toast.error(t('error update'))
            }
        })
        instance.options.meta?.updateData(row.index, id, value)
    }

    if (row.original.invalid) {
        return (
            <div className="flex justify-center md:w-full rights-input">
                <span className="text-sm select-none label-text">
                    {t('missing email')}
                </span>
            </div>
        )
    }

    return (
        <div className="flex justify-center md:w-full rights-input">
            {rightsLoaded ?
                <input
                    id="gotRightInput"
                    type="checkbox"
                    checked={getValue()}
                    onChange={handleChange}
                    className="checkbox checkbox-primary"
                /> : <Spinner />
            }
        </div>
    )
}

export default RightManagement
