import { Dialog } from '@headlessui/react'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import { imageOverlayOpened } from '../../components/Jotai'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css/bundle';


// import required modules
import { Navigation, Pagination } from 'swiper/modules';

import parkingEntry from '../../../assets/img/TG_Anfahrt_01.webp'
import parkingSouth from '../../../assets/img/TG_Anfahrt_02.webp'
import parkingNorth from '../../../assets/img/TG_Anfahrt_03.webp'
import parkingExit1 from '../../../assets/img/Parkplatz_TG_001.jpg'
import parkingExit2 from '../../../assets/img/Parkplatz_TG_002.jpg'

type props = {}

const ImageOverlay = (props: props) => {

    const { t } = useTranslation(['usefullInformation'])
    const [isOpen, setIsOpen] = useAtom(imageOverlayOpened)

    return (
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            className="relative z-[9999]"
        >
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 flex justify-center w-screen p-4">
                <Dialog.Panel className="relative w-3/4 bg-white rounded h-5/6">
                    <span
                        onClick={() => setIsOpen(false)}
                        className="font-[BJEAvertaBold] border-2 border-bje-light text-lg absolute right-2 p-2 top-2 rounded-lg text-bje-dark-grey w-[42px] text-center z-[9999]">X</span>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        navigation={true}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination, Navigation]}
                        speed={600}
                    >
                        <SwiperSlide>
                            <div className="flex flex-col items-center justify-center h-full swiper-slide">
                                <span className="w-5/6 my-6 text-lg text-left">{t('parking entry')}</span>
                                <img className="max-w-[80%] max-h-[80%] h-full" src={parkingEntry} />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex flex-col items-center justify-center h-full swiper-slide">
                                <span className="w-5/6 my-6 text-lg text-left">{t('parking south')}</span>
                                <img className="max-w-[80%] max-h-[75%]h-full" src={parkingSouth} />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex flex-col items-center justify-center h-full swiper-slide">
                                <span className="w-5/6 my-6 text-lg text-left">{t('parking north')}</span>
                                <img className="max-w-[80%] max-h-[80%] h-full" src={parkingNorth} />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex flex-col items-center justify-center swiper-slide hfull">
                                <span className="w-5/6 my-6 text-lg text-left">{t('parking exit')}</span>
                                <img className="max-w-[80%] max-h-[80%] h-full" src={parkingExit1} />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex flex-col items-center justify-center swiper-slide hfull">
                                <img className="max-w-[80%] max-h-[80%] h-full" src={parkingExit2} />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </Dialog.Panel>
            </div>
        </Dialog>
    )
}

export default ImageOverlay
