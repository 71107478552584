import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { format, differenceInHours } from 'date-fns' // eslint-disable-line import/no-duplicates
import deLocale from 'date-fns/locale/de' // eslint-disable-line import/no-duplicates
import clsx from 'clsx'
import { UseMutationResult } from 'react-query'
import { AxiosResponse } from 'axios'
import { inviteDialogAtom, acceptDialogAtom } from '../Jotai'

import {
    getIcal,
    getQr,
    getApplePasskit,
    getGooglePasskit
} from '../../utils/api'
import ToggleSwitch from '../../components/ToggleSwitch'
import downloadIcon from '../../../assets/img/downloadIcon.svg'
import shareIcon from '../../../assets/img/shareIcon.svg'
import qrIcon from '../../../assets/img/ABB_picto_48x48_08_qr_0370.svg'
import appleWalletIcon from '../../../assets/img/DE_Add_to_Apple_Wallet_RGB_101421.svg'
import googleWalletIcon from '../../../assets/img/de_add_to_google_wallet_add-wallet-badge.svg'
import { Ticket } from '../../types'
import { accessTokenAtom } from '../../customHooks/auth'
import { useAtom, useSetAtom } from 'jotai'
import { parsedDate } from './functions'

type Props = {
    data: Ticket[] | undefined
    type: string
    showPast: boolean
    mutation: UseMutationResult<AxiosResponse<any, any>, unknown, number, unknown>
    actions: {
        page: Dispatch<SetStateAction<number>>
        setShowPast: Dispatch<SetStateAction<boolean>>
    }
}

const TableView = ({ data, type, actions, showPast, mutation }: Props) => {

    const setIsOpenInvite = useSetAtom(inviteDialogAtom)
    const setIsOpenAccept = useSetAtom(acceptDialogAtom)
    const { t } = useTranslation(['ownAppointment'])
    const now = new Date()
    const startOfToday = new Date()
    startOfToday.setHours(0, 0, 0)
    const endOfToday = new Date()
    endOfToday.setHours(23, 59, 59)

    const [accessToken] = useAtom(accessTokenAtom)

    return <div className="overflow-x-auto">
        <table className="table w-full table-compact">
            <thead>
                <tr>
                    <th style={{ position: 'relative' }}>{t('meeting')}</th>
                    <th>{t('time')}</th>
                    <th>{t('comments')}</th>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    {type === 'own' && <th colSpan={2}>
                        <div className="flex justify-end">
                            <ToggleSwitch
                                checked={showPast}
                                onChange={(e) => {
                                    actions.page(1)
                                    actions.setShowPast(e.target.checked)
                                }} label={t('showPast')} />
                        </div>
                    </th>}
                </tr>
            </thead>
            <tbody>
                {data && Array.isArray(data)
                    ? data.map((appointment: Ticket) => {
                        try {
                            const beginDate = parsedDate(appointment?.begin)
                            const endDate = parsedDate(appointment?.end)

                            const handleMutate = () => {
                                if (appointment?.id) {
                                    mutation.mutate(appointment.id)
                                }
                            }

                            const difference = differenceInHours(beginDate, now)
                            const differenceToStartOfDay = differenceInHours(
                                beginDate,
                                startOfToday,
                            )
                            const differenceToEndOfDay = differenceInHours(
                                beginDate,
                                endOfToday,
                            )

                            const handleCancel = () => {
                                if (appointment?.id && difference > 24) {
                                    setIsOpenAccept({
                                        accept: handleMutate,
                                        title: 'invite cancel title',
                                        titleProps: { date: format(beginDate, 'dd.LL.uuuu') },
                                        subtitle: 'invite cancel subtitle',
                                        subtitleProps: {
                                            date: format(beginDate, 'dd.LL.uuuu'),
                                            begin: format(beginDate, 'HH:mm', {
                                                locale: deLocale,
                                            }),
                                            end: format(endDate, 'HH:mm', { locale: deLocale }),
                                        },
                                        buttonLabel: 'invite cancel button',
                                    })
                                }
                            }

                            const handleShare = () => {
                                setIsOpenInvite([appointment])
                            }

                            const downloadCal = () => {
                                if (appointment.id) {
                                    getIcal(accessToken, appointment.id)
                                }
                            }

                            const downloadQr = () => {
                                if (appointment.id) {
                                    getQr(accessToken, appointment.id)
                                }
                            }

                            const downloadApplePasskit = () => {
                                if (appointment.id) {
                                    getApplePasskit(accessToken, new Date(appointment?.begin))
                                }
                            }

                            const downloadGooglePasskit = () => {
                                if (appointment.id) {
                                    getGooglePasskit(accessToken, new Date(appointment?.begin))
                                }
                            }

                            return (
                                <tr key={appointment?.id}>
                                    <td>{format(beginDate, 'dd.LL.uuuu')}</td>
                                    <td>
                                        {format(beginDate, 'HH:mm', { locale: deLocale })}-
                                        {format(endDate, 'HH:mm', { locale: deLocale })}{' '}
                                        {t('clock')}
                                    </td>
                                    <td>{appointment?.comment}</td>
                                    {type === 'own' && (
                                        <td className="text-right">
                                            <div className="flex gap-4">
                                                <div className="opacity-100 tooltip" data-tip={t("tooltip share")}>
                                                    <button
                                                        type="button"
                                                        className="w-4"
                                                        onClick={handleShare}
                                                    >
                                                        <img src={shareIcon} alt={t('share')} />
                                                    </button>
                                                </div>
                                                <div className="opacity-100 tooltip" data-tip={t("tooltip download")}>
                                                    <button
                                                        type="button"
                                                        className="w-4"
                                                        onClick={downloadCal}
                                                    >
                                                        <img src={downloadIcon} alt={t('download')} />
                                                    </button>
                                                </div>
                                                {differenceToStartOfDay >= 0 &&
                                                    differenceToEndOfDay < 24 * 5 && (
                                                        <div className="opacity-100 tooltip" data-tip={t("tooltip qr download")}>
                                                            <button
                                                                type="button"
                                                                className="w-4"
                                                                onClick={downloadQr}
                                                            >
                                                                <img src={qrIcon} alt={t('qr download')} />
                                                            </button>
                                                        </div>
                                                    )
                                                }
                                                {differenceToStartOfDay >= 0 &&
                                                    differenceToEndOfDay < 24 * 5 && (
                                                        <div className="opacity-100 tooltip" data-tip={t("tooltip passkit download")}>
                                                            <button
                                                                type="button"
                                                                className="w-32"
                                                                onClick={downloadApplePasskit}
                                                            >
                                                                <img src={appleWalletIcon} alt={t('passkit download')} />
                                                            </button>
                                                        </div>
                                                    )
                                                }
                                                {differenceToStartOfDay >= 0 &&
                                                    differenceToEndOfDay < 24 * 5 && (
                                                        <div className="opacity-100 tooltip" data-tip={t("tooltip passkit download")}>
                                                            <button
                                                                type="button"
                                                                className="w-32"
                                                                onClick={downloadGooglePasskit}
                                                            >
                                                                <img src={googleWalletIcon} alt={t('passkit download')} />
                                                            </button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </td>
                                    )}
                                    {type === 'own' && (
                                        <td className="text-right">
                                            <button
                                                type="button"
                                                disabled={difference < 24}
                                                className={clsx(
                                                    'btn h-12 px-4 text-sm normal-case border-2 border-bje-primary bg-white text-bje-primary'
                                                )}
                                                onClick={handleCancel}
                                            >{t('cancel')}
                                            </button>
                                        </td>
                                    )}
                                </tr>
                            )
                        } catch (e) {
                            return null
                        }
                    })
                    : null}
            </tbody>
        </table>
    </div>
}


export default TableView
