import React, { Dispatch, SetStateAction } from 'react'
import { format, formatISO, compareAsc } from 'date-fns'
import clsx from 'clsx'
import { useQuery } from 'react-query'
import { getTimesForDate } from '../../utils/api'
import { TimesForDate, SelectedAppointmentType } from '../../types'
import ErrorBoundary, { ErrorFallback } from '../ErrorBoundary'
import Spinner from '../Spinner'
import { useAtom } from 'jotai'
import { accessTokenAtom } from '../../customHooks/auth'
import { useTranslation } from 'react-i18next'

type Props = {
    selectedDay: Date
    selectedAppointments: SelectedAppointmentType[]
    setSelectedAppointments: Dispatch<SetStateAction<SelectedAppointmentType[]>>
}

export default function SelectedAppointment({
    selectedDay,
    selectedAppointments,
    setSelectedAppointments,
}: Props) {
    const [accessToken,] = useAtom(accessTokenAtom)

    const { isLoading, isError, data, error } = useQuery<TimesForDate[], Error>(
        [`getTimesForDate`, { accessToken, day: formatISO(selectedDay) }],
        () => getTimesForDate(accessToken, selectedDay),
        { retry: 0 },
    )

    const { t } = useTranslation(["booking"])

    if (isLoading) {
        return <Spinner size="small" />
    }

    if (isError) {
        if (error instanceof Error) {
            return <ErrorFallback error={error} />
        }
        return <ErrorFallback error={{ message: 'Error' }} />
    }

    return (
        <ErrorBoundary>
            <div className="grid md:grid-cols-6 gap-4 text-lg p-6 font-[BJEAvertaBold]">
                <div className="md:col-span-6 col-span-4 text-bje-dark-grey font-[BJEAverta]">{t('invite_time_choose')}</div>
                {data && Array.isArray(data)
                    ? data.map((appointment, index) => {
                        const beginDate = new Date(appointment.begin)
                        const endDate = new Date(appointment.end)

                        const selectedIndex = selectedAppointments.findIndex(el => {
                            return (
                                formatISO(el.begin) === formatISO(beginDate) &&
                                formatISO(el.end) === formatISO(endDate)
                            )
                        })

                        const handleClick = (e: React.MouseEvent) => {
                            if (appointment.free) {
                                if (selectedIndex === -1) {
                                    if (e.shiftKey) {
                                        const newSelectedIndex = data.findIndex(el => {
                                            return (
                                                formatISO(new Date(el.begin)) ===
                                                formatISO(beginDate) &&
                                                formatISO(new Date(el.end)) === formatISO(endDate)
                                            )
                                        })
                                        let prevIndex = -1
                                        selectedAppointments.forEach(appointment => {
                                            let index = data.findIndex(el => {
                                                return (
                                                    formatISO(new Date(el.begin)) ===
                                                    formatISO(appointment.begin) &&
                                                    formatISO(new Date(el.end)) ===
                                                    formatISO(appointment.end)
                                                )
                                            })
                                            if (index < newSelectedIndex) {
                                                prevIndex = Math.max(index, prevIndex)
                                            }
                                        })
                                        let valid = true
                                        let datesBetween = data
                                            .slice(prevIndex + 1, newSelectedIndex)
                                            .map(date => {
                                                if (!date.free) {
                                                    valid = false
                                                }
                                                return {
                                                    begin: new Date(date.begin),
                                                    end: new Date(date.end),
                                                }
                                            })
                                        if (valid) {
                                            setSelectedAppointments(
                                                (value: SelectedAppointmentType[]) => {
                                                    return [
                                                        ...value,
                                                        ...datesBetween,
                                                        { begin: beginDate, end: endDate },
                                                    ].sort((a, b) => compareAsc(a.begin, b.begin))
                                                },
                                            )
                                        }
                                    } else {
                                        setSelectedAppointments(
                                            (value: SelectedAppointmentType[]) => {
                                                return [
                                                    ...value,
                                                    { begin: beginDate, end: endDate },
                                                ].sort((a, b) => compareAsc(a.begin, b.begin))
                                            },
                                        )
                                    }
                                } else {
                                    setSelectedAppointments(value => [
                                        ...value.slice(0, selectedIndex),
                                        ...value.slice(selectedIndex + 1),
                                    ])
                                }
                            }
                        }

                        return (
                            <div
                                key={`appointment-${index}`}
                                onClick={handleClick}
                                className={clsx(
                                    'flex rounded-lg flex-col py-2 px-1 border-2 cursor-pointer justify-items-center text-center text-base md:text-lg bg-bje-light-grey border-bje-light-grey font-bold',
                                    selectedIndex > -1
                                        ? 'border-bje-primary bg-white text-bje-primary'
                                        : appointment.reservedByMe
                                            ? 'border-lime-500 text-lime-500'
                                            : !appointment.free
                                                ? 'text-bje-dark-grey bg-bje-light-grey border-bje-light-grey'
                                                : 'bg-sky-200 border-sky-200',
                                )}
                            >
                                {format(beginDate, 'HH')}-{format(endDate, 'HH')}
                            </div>
                        )
                    })
                    : null}
            </div>
        </ErrorBoundary>
    )
}
