import { useTranslation } from 'react-i18next'
import { useQueryClient, useMutation } from 'react-query'
import toast from 'react-hot-toast'
import ErrorBoundary from '../ErrorBoundary'
import Invite from '../Invite'

import { Dispatch, SetStateAction, useContext } from 'react'

import { Ticket } from '../../types'
import {
    deleteAppointment,
} from '../../utils/api'

import { MobileContext } from '../..'

import TableView from './TableView'
import BlockView from './BlockView'
import { useAtom } from 'jotai'
import { accessTokenAtom } from '../../customHooks/auth'

type Props = {
    data: Ticket[] | undefined
    type: string
    showPast: boolean
    actions: {
        page: Dispatch<SetStateAction<number>>
        setShowPast: Dispatch<SetStateAction<boolean>>
    }
}

export default function App({ data, type, actions, showPast }: Props) {
    const { t } = useTranslation(['ownAppointment'])

    const queryClient = useQueryClient()

    const isMobile = useContext(MobileContext)
    const [accessToken] = useAtom(accessTokenAtom)

    const mutation = useMutation(
        (id: number) => deleteAppointment(accessToken, id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(`appointsForMonth`)
                queryClient.invalidateQueries(`getTimesForDate`)
                queryClient.invalidateQueries(`appointmentsForDate`)
                queryClient.invalidateQueries(`ownAppointments`)
            },
            onError: () => {
                toast(t('error delete appointment'))
            },
        },
    )

    return (
        <ErrorBoundary>
            {!isMobile ?
                <TableView
                    data={data}
                    type={type}
                    actions={actions}
                    mutation={mutation}
                    showPast={showPast} /> :
                <BlockView
                    data={data}
                    type={type}
                    actions={actions}
                    mutation={mutation}
                    showPast={showPast} />
            }
            <Invite />
        </ErrorBoundary>
    )
}
